import * as api from '../../config/api';

import {
  SET_CALL_PARTNER,
  SET_LOCAL_STREAM,
  SET_REMOTE_STREAM,
  SET_LOCAL_DEVICES,
  SET_REMOTE_DEVICES,
  SET_ENCRYPT_ENABLED,
  SET_CURRENT_KEY,
  SET_LOCAL_SELECTED,
  SET_CALL_STARTED,
  SET_HAS_ECHO_CANCELLATION,
  SET_LOCAL_AUDIO_ENABLED,
  SET_LOCAL_VIDEO_ENABLED,
  SET_REMOTE_ENCRYPT_ENABLED,
  SET_USER_LIST,
  SIGNALING_SERVER_LOGIN,
  SET_CALL_MEMBER_ID,
  CLEAR_CALL,
  SET_CALL_ENDED_BY_REMOTE,
  SET_ENCRYPTION_AVAILABLE,
  SET_AUDIO_ONLY,
} from '../actionTypes/one2onevcall';

const apiUrl = api.getAPIUrl();

export const setAudioOnly = (audioOnly) => ({
  type: SET_AUDIO_ONLY,
  payload: audioOnly,
});

export const setCallPartner = (partner) => ({
  type: SET_CALL_PARTNER,
  payload: {
    partner,
  },
});

export const setClearCall = () => (dispatch) => {
  dispatch({ type: CLEAR_CALL });
};

export const setCallPartnerMemberId = (member_id, callType = 'call') => ({
  type: SET_CALL_MEMBER_ID,
  payload: {
    member_id,
    callType,
  },
});

export const getMemberInfo = (member_id) => {
  const url = `${apiUrl}/member/info/${member_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({
          type: 'SET_MEMBER_INFO',
          payload: {
            title: res.data.job_title,
            company: res.data.company_name,
            amera_avatar_url: res.data.amera_avatar_url,
            email: res.data.email,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setCallEndedByRemote = (callEndedByRemote) => ({
  type: SET_CALL_ENDED_BY_REMOTE,
  payload: {
    callEndedByRemote,
  },
});

export const setRemoteStream = (stream) => ({
  type: SET_REMOTE_STREAM,
  payload: {
    remoteStream: stream,
  },
});

export const setLocalStream = (stream) => ({
  type: SET_LOCAL_STREAM,
  payload: {
    localStream: stream,
  },
});

export const setLocalDevices = (devices) => ({
  type: SET_LOCAL_DEVICES,
  payload: {
    localDevices: devices,
  },
});

export const setRemoteDevices = (devices) => ({
  type: SET_REMOTE_DEVICES,
  payload: {
    remoteDevices: devices,
  },
});

export const setEncryptEnabled = (encryptEnabled) => {
  return {
    type: SET_ENCRYPT_ENABLED,
    payload: {
      encryptEnabled,
    },
  };
};

export const setCurrentKey = (currentKey) => ({
  type: SET_CURRENT_KEY,
  payload: {
    currentKey,
  },
});

export const setLocalSelected = (localSelected) => ({
  type: SET_LOCAL_SELECTED,
  payload: {
    localSelected,
  },
});

export const setCallStarted = (callStarted) => ({
  type: SET_CALL_STARTED,
  payload: {
    callStarted,
  },
});

export const setHasEchoCancellation = (hasEchoCancellation) => {
  return {
    type: SET_HAS_ECHO_CANCELLATION,
    payload: {
      hasEchoCancellation,
    },
  };
};

export const setLocalAudioEnabled = (localAudioEnabled) => ({
  type: SET_LOCAL_AUDIO_ENABLED,
  payload: {
    localAudioEnabled,
  },
});

export const setLocalVideoEnabled = (localVideoEnabled) => ({
  type: SET_LOCAL_VIDEO_ENABLED,
  payload: {
    localVideoEnabled,
  },
});

export const setRemoteEncryptEnabled = (remoteEncryptEnabled) => ({
  type: SET_REMOTE_ENCRYPT_ENABLED,
  payload: {
    remoteEncryptEnabled,
  },
});

export const setUserList = (userList) => ({
  type: SET_USER_LIST,
  payload: {
    userList,
  },
});

export const setSignalingServerLogin = (login) => ({
  type: SIGNALING_SERVER_LOGIN,
  payload: {
    login,
  },
});

export const setEncryptionAvailable = (encryptionAvailable) => ({
  type: SET_ENCRYPTION_AVAILABLE,
  payload: {
    encryptionAvailable,
  },
});
