import { fade, makeStyles } from '@material-ui/core/styles';
// import { important } from '@chonky/util/styles';
import { dark, green, light, textGrey } from '../../../../jss/colors';

export const useSettingsStyles = makeStyles((theme) => ({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  customLabel: {
    color: '#848AC1',
    fontWeight: 'bold',
  },
  signatureTitle: {
    color: '#848AC1',
    fontWeight: ['bold', '!important'],
    font: 'normal normal bold 26px/24px ',
  },
  signatureSubtitle: {
    color: '#848AC1',
    font: 'normal normal normal 20px/24px',
  },
  formControlLabelRoot: {
    marginLeft: '0px',
  },
  rotate: {
    animationDuration: '1s',
    animationName: '$rotate',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
  },
  root: {
    paddingLeft: theme.spacing(5),
  },
  listItem: {
    display: 'block',
  },
  mailContentWrapper: {
    height: '83%',
    paddingRight: '50px',
    padding: theme.spacing(2),
  },
  groupWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  groupWrapperTop: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(5),
  },
  groupWrapperTopLeft: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
  },
  inboxIcon: {
    color: dark,
    width: 20,
  },
  select: {
    '& .MuiPaper-root': {
      backgroundColor: green,
      color: 'white',
      justifyContent: 'center',
      width: 100,
      borderRadius: 0,
      marginTop: -10,
    },
  },
  buttonmail: {
    margin: 5,
    maxWidth: 118,
    minWidth: 118,
    maxHeight: 36,
    minHeight: 36,
    fontSize: 12,
    fontFamily: 'Roboto',
    justifyContent: 'space-between',
  },
  scroll: {
    height: '100%',
    overflow: 'auto',
    paddingRight: '10px',
    paddingBottom: '20px',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      'background-color': light,
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      color: 'white',
      'background-color': 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      'background-color': textGrey,
    },
  },
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginTop: '20px',
    marginRight: theme.spacing(2),
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  h100: {
    height: '100%',
  },
  child: {
    position: 'absolute',
    bottom: 0,
    right: 30,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  radio: {
    borderRadius: '1px',
    padding: '1px',
    border: `${green} 1px solid`,
    width: 13,
    height: 13,
  },
  radioON: {
    width: '100%',
    height: '100%',
    background: green,
    border: `1px solid ${green}`,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '1px',
  },
  radioContent: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
}));
