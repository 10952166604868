import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from './modal';
import VideoEmailEditor from '../VideoEmailEditor';

/**
 * @component
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Function} props.onClose
 * @param {Number} props.receiver
 * @param {String} props.type
 * @param {String} props.name
 * @param {Number} [props.replied_id]
 * @param {String} [props.subject]
 */
const VideoEmailEditorModal = ({
  open,
  onClose,
  receiver,
  type,
  name,
  replied_id = undefined,
  subject = undefined,
}) => {
  const [mediaType, setMediaType] = useState('video');

  let title = useMemo(() => {
    let tmp = `Create ${
      mediaType === 'audio' ? 'Audio' : 'Video'
    } Mail to: ${name}`;
    if (type === 'group') tmp = `${tmp} Group`;
    else if (type === 'reply')
      tmp = `Create Reply ${
        mediaType === 'audio' ? 'Audio' : 'Video'
      } Mail to: ${name}(${subject})`;
    return tmp;
  }, [mediaType, type, name, subject]);

  const closeModal = () => {
    onClose();
  };

  const ModalContent = () => (
    <VideoEmailEditor
      show={open}
      receiver={receiver}
      type={type}
      mediaType={mediaType}
      replied_id={replied_id}
      replySubject={subject}
      setMediaType={setMediaType}
    />
  );

  const ModalAction = () => (
    <Button variant="contained" color="secondary" onClick={closeModal}>
      Close
    </Button>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        maxWidth="lg"
        disableBackdropClick={true}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

VideoEmailEditorModal.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  receiver: PropTypes.any,
};

VideoEmailEditorModal.defaultProps = {
  open: false,
  type: 'contact',
};

export default VideoEmailEditorModal;
