import React, { useEffect, useState, useCallback, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { setContactAlert } from '../../utils/alert';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import _, { capitalize, debounce } from 'lodash';

import styles from '../../jss/views/activityTableStyle';
import * as api from '../../config/api';

// import DeleteIcon from '@material-ui/icons/Delete';
import TimeZoneAutocomplete from '../../components/Modal/EventModals/TimeZoneAutocomplete';
import ContactProfile from '../Profile/Profiles/ContactProfile';
import ConfirmDialog from '../../components/ConfirmDialog';
import { setReduxContactAlert } from '../../redux/actions/contact';
import { defaultDateTimeZone } from '../../utils/calendar';

const headCells = [
  {
    id: 'first_name',
    label: 'Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company_name',
    label: 'Company',
    minWidth: 170,
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
  },
  {
    id: 'region',
    label: 'Region',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'ip_address',
    label: 'IP Address',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'inviter_first_name',
    label: 'Invited By',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'date_invited',
    label: 'Date Invited',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'date_registered',
    label: 'Date registered',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
  },
];

const EnhancedTableHead = ({
  classes,
  order,
  orderBy,
  onRequestSort,
  isCanSeeAllInvites,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells
          .filter(
            (headCell) =>
              (!isCanSeeAllInvites &&
                headCell.label !== 'IP Address' &&
                headCell.label !== 'Invited By') ||
              isCanSeeAllInvites
          )
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(styles);

const InviteActivity = ({
  isCanSeeAllInvites = false,
  timeZone,
  setTimeZone,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const contactAlert = useSelector((state) => state.contact.contactAlert);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState([]);
  const [choiceMember, setChoiceMember] = useState({});

  const [searchKey, setSearchKey] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date_invited');

  const [currentDialog, setCurrentDialog] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);

  const isMountedRef = useRef(false);

  const formatName = (user) => {
    return `${capitalize(user.first_name)} ${capitalize(user.last_name)}`;
  };

  const message_resend_email = `Are you sure to send the invite to ${formatName(
    selectedItem
  )} again?`;
  const message_delete_invite = `Are you sure to withdraw the invite sent to ${formatName(
    selectedItem
  )}?`;
  const message_delete_member = `Are you sure to disable ${formatName(
    selectedItem
  )}?`;

  const search = (event) => {
    setSearchKey(event.target.value);
    let sortItems = '',
      params = {};
    sortItems = order === 'desc' ? `-${orderBy}` : `${orderBy}`;
    params = { type: 'sort', sortItems, searchKey: event.target.value };
    getList(params);
  };

  const getList = useCallback(
    debounce((params = {}) => {
      let uri = `?search_key=${
        params.searchKey ? params.searchKey : ''
      }&get_all=${isCanSeeAllInvites}`;
      if (params && params.type === 'sort') {
        uri = uri + '&sort=' + params.sortItems;
      }
      // const url = `${api.apiUrl}/system/activity/invite?search_key=${searchKey}&page_number=${page}&page_size=${rowsPerPage}`;
      const url = `${api.apiUrl}/system/activity/invite${uri}`;
      api
        .GET(url)
        .then((res) => {
          if (isMountedRef.current && res?.activities) {
            setData(res.activities);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    [isCanSeeAllInvites]
  );

  const getInfo = (member_id) => {
    api
      .GET(`${api.apiUrl}/member/info/${member_id}`)
      .then((res) => {
        setChoiceMember(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getList();

    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, [getList]);

  const loadSortedInvites = (property, order) => {
    let sortItems = '',
      params = {};
    sortItems = order === 'desc' ? `-${property}` : `${property}`;
    params = { type: 'sort', sortItems, searchKey };
    getList(params);
    setOrderBy(property);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedInvites(property, newOrder);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const resendEmail = ({ id }) => {
    setLoading(true);
    api
      .PUT(
        `${api.apiUrl}/member/invite`,
        JSON.stringify({
          id: id,
        }),
        {
          'Content-Type': 'application/json',
        }
      )
      .then((res) => {
        if (res.success && res.data) {
          const updatedInvites = data.map((invite) => {
            if (invite.id === res.data.id) {
              invite.date_invited = res.data.date_invited;
            }
            return invite;
          });
          setData(updatedInvites);
          setCurrentDialog('');
          setSelectedItem({});

          const message = 'Invite sent successfully.';
          const payload = setContactAlert(null, true, message);
          dispatch(setReduxContactAlert(payload));
          setLoading(false);
        }
      })
      .catch((error) => {
        const payload = setContactAlert(error, false);
        dispatch(setReduxContactAlert(payload));
        setLoading(false);
      });
  };
  const disableMember = (member_id) => {
    setLoading(true);
    api
      .DELETE(`${api.apiUrl}/admin/member/${member_id}`)
      .then((res) => {
        if (res.success && res.id) {
          let activities = [...data];
          _.remove(activities, {
            registered_member_id: res.id[0],
          });
          setData(activities);
          const message = 'Member disabled.';
          const payload = setContactAlert(null, true, message);
          dispatch(setReduxContactAlert(payload));
          setCurrentDialog('');
          setSelectedItem({});
        }
        setLoading(false);
      })
      .catch((error) => {
        setContactAlert(error, false);
        setLoading(false);
      });
  };
  const deleteInvite = (invite_key) => {
    setLoading(true);
    api
      .DELETE(
        `${api.apiUrl}/member/invite`,
        JSON.stringify({
          invite_key: invite_key,
        }),
        { 'Content-Type': 'application/json' }
      )
      .then((res) => {
        if (res.success) {
          let activities = [...data];
          _.remove(activities, {
            invite_key: invite_key,
          });
          setData(activities);
          const message = 'Invite deleted.';
          const payload = setContactAlert(null, true, message);
          dispatch(setReduxContactAlert(payload));
          setCurrentDialog('');
          setSelectedItem({});
        } else {
          const message = 'Failed to delete Invite';
          const payload = setContactAlert(null, false, message);
          dispatch(setReduxContactAlert(payload));
        }
        setLoading(false);
      })
      .catch((error) => {
        const payload = setContactAlert(error, false);
        dispatch(setReduxContactAlert(payload));
        setLoading(false);
      });
  };
  const handleAction = (type, data) => {
    setCurrentDialog(type);
    setSelectedItem(data);
  };
  const handleOk = () => {
    if (currentDialog === 'resend') {
      resendEmail(selectedItem);
    } else if (currentDialog === 'delete-invite') {
      deleteInvite(selectedItem.invite_key);
    } else if (currentDialog === 'delete-member') {
      disableMember(selectedItem.registered_member_id);
    }
  };
  const handleClose = () => {
    setCurrentDialog('');
    setSelectedItem({});
  };

  const redirectToActivities = (member_id) => {
    history.push(
      `${process.env.PUBLIC_URL}/statistic/user-behavior?id=${member_id}`
    );
  };

  const renderUserName = (row) => {
    const fullName = `${row.first_name} ${row.last_name}`;
    if (row.status === 'Registered') {
      if (isCanSeeAllInvites) {
        return (
          <Tooltip title={`Show ${fullName} activities`} placement="top">
            <Link
              className={classes.tableCellLink}
              component="button"
              onClick={() => redirectToActivities(row.registered_member_id)}
            >
              {fullName}
            </Link>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={`Show ${fullName} Profile`} placement="top">
            <Link
              component="button"
              onClick={() => {
                setShowDetailModal(true);
                getInfo(row.registered_member_id);
              }}
            >
              {fullName}
            </Link>
          </Tooltip>
        );
      }
    } else {
      return fullName;
    }
  };

  const handleSnackbarClose = useCallback(() => {
    if (contactAlert.show) {
      const alertOption = {
        show: false,
        variant: contactAlert.variant,
        message: '',
      };
      dispatch(setReduxContactAlert(alertOption));
    }
  }, [dispatch, contactAlert]);

  useEffect(() => {
    if (contactAlert.show) {
      setTimeout(() => {
        handleSnackbarClose();
      }, 4000);
    }
  }, [contactAlert, handleSnackbarClose]);

  return (
    <Paper className={classes.paper}>
      <ContactProfile
        open={showDetailModal}
        onClose={() => {
          setShowDetailModal(false);
        }}
        contact={choiceMember}
      />
      <Toolbar variant="dense" className={classes.searchRoot}>
        <div className={classes.search}>
          <div aria-label="search-icon" className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchKey}
            onChange={search}
          />
        </div>
        <div className={classes.settingsWrapper}>
          <TimeZoneAutocomplete
            variant="outlined"
            value={timeZone}
            onChange={(e, value) => setTimeZone(value)}
          />
          <FormControl variant="outlined" className={classes.filerDropDown}>
            <InputLabel id="demo-simple-select-outlined-label">
              Sort By
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={orderBy}
              onChange={(e) => {
                loadSortedInvites(e.target.value, 'asc');
                setOrderBy(e.target.value);
              }}
              label="Sort By"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {headCells
                .filter(
                  (headCell) =>
                    (!isCanSeeAllInvites &&
                      headCell.label !== 'IP Address' &&
                      headCell.label !== 'Invited By') ||
                    isCanSeeAllInvites
                )
                .map((headCell) => (
                  <MenuItem key={headCell.id} value={headCell.id}>
                    {headCell.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </Toolbar>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="activityTable"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            isCanSeeAllInvites={isCanSeeAllInvites}
          />
          <TableBody>
            {data
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`invite_activity_${index}`}
                  >
                    <TableCell className={classes.tableCellUser}>
                      {renderUserName(row)}
                    </TableCell>
                    <TableCell>
                      {row.company_name ? row.company_name : ''}
                    </TableCell>
                    <TableCell>{row.city ? row.city : ''}</TableCell>
                    <TableCell>{row.region ? row.region : ''}</TableCell>
                    <TableCell>{row.country ? row.country : ''}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    {isCanSeeAllInvites && (
                      <>
                        <TableCell>
                          {row.ip_address ? row.ip_address : ''}
                        </TableCell>
                        <TableCell>{`${row.inviter_first_name} ${row.inviter_last_name}`}</TableCell>
                      </>
                    )}
                    <TableCell>
                      {row.date_invited
                        ? defaultDateTimeZone(
                            row.date_invited,
                            'date',
                            true,
                            timeZone
                          )
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {row.registered_date
                        ? defaultDateTimeZone(
                            row.registered_date,
                            'date',
                            true,
                            timeZone
                          )
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {row.member_status === 'inactive'
                        ? 'Disabled'
                        : row.status}
                    </TableCell>
                    <TableCell>
                      {row.status === 'Registered' && isCanSeeAllInvites ? (
                        isCanSeeAllInvites && row.member_status === 'active' ? (
                          <Tooltip title="Disable access">
                            <IconButton
                              onClick={() => handleAction('delete-member', row)}
                            >
                              <DeleteIcon className={classes.deleteUserIcon} />
                            </IconButton>
                          </Tooltip>
                        ) : null
                      ) : (
                        row.status === 'Unregistered' && (
                          <ButtonGroup
                            variant="text"
                            aria-label="text primary button group"
                          >
                            <Tooltip title="Send invite E-mail again">
                              <Button
                                onClick={() => handleAction('resend', row)}
                              >
                                <RotateLeftRoundedIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Withdraw invite from system">
                              <Button
                                onClick={() =>
                                  handleAction('delete-invite', row)
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[25, 50, 75, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
      {data && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(Math.ceil(data.length / rowsPerPage))}
            page={page}
            onChange={handleChangePage}
            defaultPage={1}
            color="primary"
            showFirstButton
            showLastButton
          />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={contactAlert.show}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={contactAlert.variant}
            >
              {contactAlert.message}
            </Alert>
          </Snackbar>
        </div>
      )}
      <ConfirmDialog
        open={
          currentDialog === 'resend' ||
          currentDialog === 'delete-invite' ||
          currentDialog === 'delete-member'
        }
        title="Confirm"
        loading={loading}
        onOk={handleOk}
        content={
          currentDialog === 'resend'
            ? message_resend_email
            : currentDialog === 'delete-invite'
            ? message_delete_invite
            : currentDialog === 'delete-member'
            ? message_delete_member
            : ''
        }
        onCancel={handleClose}
      />
    </Paper>
  );
};

InviteActivity.propTypes = {
  isCanSeeAllInvites: PropTypes.bool,
};

export default InviteActivity;
