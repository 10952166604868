import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// import VideoCallIcon from '@material-ui/icons/VideoCall';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Menu, MenuItem } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import SendIcon from '@material-ui/icons/Send';

import EncryptionToggle from '../../../components/EncryptionToggle';
import Gallery from 'react-grid-gallery';

import { stropheSendFiles } from '../../../redux/actions/chat';
import { AttachmentSVG } from '../../../assets/svg';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { textCapitalized } from '../../../utils/general';
import AMERAIoTLogoShield from '../../../assets/img/amera-iot-shield.png';

const ShowInputFiles = React.memo(({ files }) => {
  let photos = Object.keys(files)
    .map((key) => {
      if (files[key]['type']?.match(/gif|png|jpg|jpeg/)) {
        let src = URL.createObjectURL(files[key]);
        return {
          src,
          thumbnail: src,
          thumbnailWidth: 120,
          thumbnailHeight: 90,
        };
      }
      return {
        src: AttachmentSVG,
        thumbnail: AttachmentSVG,
        tags: [
          {
            value: files[key]['name']?.substring(0, 20),
            title: files[key]['name'],
          },
        ],
        thumbnailWidth: 120,
        thumbnailHeight: 90,
      };
    })
    .filter((el) => el !== undefined);
  return <Gallery maxRows={2} rowHeight={90} images={photos} />;
});

function ChatForm({
  handleSendMessage,
  disabled = false,
  disableFileInput = false,
  type,
  group_id = undefined,
  group_leader_id = undefined,
  contactID = undefined,
  contactFirstName = undefined,
  contactLastName = undefined,
  room,
  selectedChatID,
}) {
  const dispatch = useDispatch();

  const [messageText, setMessageText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  // @ts-ignore
  const e2eEncryptionEnabled = useSelector(
    (state) => state.security.e2eEncryptionEnabled
  );
  const [files, setFiles] = useState([]);

  const handleSend = (e) => {
    if (files.length > 0) {
      dispatch(stropheSendFiles(selectedChatID, files, messageText));
      setFiles([]);
    } else {
      if (messageText.trim() !== '') {
        handleSendMessage(messageText);
      }
    }
    setMessageText('');
    e.preventDefault();
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSend(e);
    }
  };

  const handleFileSelect = ({ target }) => {
    const newFiles = Object.keys(target.files).map((k) => target.files[k]);
    setFiles([...files, ...newFiles]);
    target.value = '';
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className="chat-form">
      <div className="chat-form-input-wrapper">
        <div className="show-input-files-list">
          <ShowInputFiles files={files} />
        </div>
        <div className="chat-form-text-input-wrapper">
          <TextareaAutosize
            rowsMax={9}
            disabled={disabled}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={onKeyDown}
            placeholder="Type message here..."
            className="chat-form-input"
          />
          <Tooltip
            title={`This conversation is ${
              e2eEncryptionEnabled ? '' : 'not '
            }encrypted`}
            placement="top"
          >
            <span className="chat-form-input-encryption-sign">
              <img
                src={AMERAIoTLogoShield}
                alt="AMERA IoT™ Inc."
                className={e2eEncryptionEnabled ? 'origin' : 'gray'}
              />
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="chat-input-action-icons">
        <input
          style={{ display: 'none' }}
          disabled={disabled || disableFileInput}
          id="icon-button-photo"
          type="file"
          multiple
          onChange={handleFileSelect}
        />
        <label htmlFor="icon-button-photo">
          <Tooltip
            title={`${
              disableFileInput
                ? 'Unable to send files, contact is offline.'
                : 'Send files to ' +
                  textCapitalized(contactFirstName) +
                  ' ' +
                  textCapitalized(contactLastName)
            }`}
            placement="top"
          >
            <IconButton
              disableRipple={disabled || disableFileInput}
              aria-label="photo-library"
              className="chat-input-action-icon"
              id="icon-button-photo"
              component="span"
            >
              <AttachFileIcon fontSize="default" />
            </IconButton>
          </Tooltip>
        </label>
        <Tooltip title="More" placement="top">
          <IconButton
            onClick={openMenu}
            aria-label="photo-library"
            className="chat-input-action-icon"
          >
            <MoreHoriz fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Send" placement="top">
          <IconButton
            onClick={handleSend}
            aria-label="send-btn"
            className="chat-input-action-icon"
          >
            <SendIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={hideMenu}>
        <MenuItem onClick={() => {}}>
          <SyncIcon /> Reconnect
        </MenuItem>
        <MenuItem>
          Encryption
          <EncryptionToggle
            type={type}
            hideMenu={hideMenu}
            contact_id={contactID}
            group_id={group_id}
            group_leader_id={group_leader_id}
            room={room}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}

ChatForm.defaultProps = {
  disabled: false,
};

export default ChatForm;
