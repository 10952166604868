import React from 'react';
import PropTypes from 'prop-types';

const Counter = ({ count, maxCountShow }) =>
  count ? (
    <span className="counter">
      {count > maxCountShow ? `${maxCountShow}+` : count}
    </span>
  ) : (
    <></>
  );

Counter.propTypes = {
  count: PropTypes.number,
  maxCountShow: PropTypes.number,
};

Counter.defaultProps = {
  maxCountShow: 99,
};

export default Counter;
