import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Card, CardContent, Grid, Tooltip } from '@material-ui/core';
import adapter from 'webrtc-adapter';
import { injectIntl } from 'react-intl';

import { AmeraAES } from '../../utils/ameraWebCrypto';

import {
  getContactSecurity,
  storeSecurityKey,
} from '../../redux/actions/security';
import { apiUrl } from '../../config/api';
import { getKeyFromStorage, getKeyFromFile } from '../../utils/keygen';
import { useParams } from 'react-router-dom';

const EncryptionController = (props) => {
  const [picture, setPicture] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [pin, setPin] = useState('');
  const [pictureStyle, setPictureStyle] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [currentKey, setCurrentKey] = useState();
  const [, setKey] = useState();

  let pictureRef = useRef();
  const {
    classes,
    partner,
    memberInfo,
    ameraWebrtcClient,
    encryptEnabled,
    isInModal,
    encryptionAvailable,
    localStream,
    security,
  } = props;

  // @ts-ignore
  const { room } = useParams();
  const [member_id, contact_id] = room.split('-');
  const security_member_id =
    memberInfo.member_id === parseInt(member_id)
      ? parseInt(contact_id)
      : parseInt(member_id);

  const dispatch = useDispatch();
  useEffect(() => {
    // debugger;
    if (security_member_id) {
      dispatch(getContactSecurity(security_member_id));
    }
  }, [security_member_id, dispatch]);

  /**
   * Get key from the security object
   */
  useEffect(() => {
    if (security) {
      storeSecurityKey(security);
      if (security.security_picture) {
        setPicture(`${apiUrl}${security.security_picture}`);
      }
      if (security.pin) {
        setPin(security.pin);
      }

      if (security.pin && security.security_picture_storage_id) {
        getKeyFromStorage(
          security.security_picture_storage_id,
          security.pin
        ).then((secure) => {
          if (secure && secure.hexkey) {
            setCurrentKey(secure.hexkey);
          }
          if (secure && secure.key) {
            setKey(secure.key);
          }
        });
      }
    }
  }, [security]);

  /**
   *
   * @param {*} event
   * @param {File} file
   * @param {String} pinParameter
   * @returns
   */
  const fetchKeyFromPicturePin = (event, file, pinParameter = pin) => {
    // debugger;
    let pictureObject = file || fileObject;

    if (event) {
      const files = event.target.files;

      for (let j = 0; j < files.length; j++) {
        const file = files[j];

        if (!file.type.startsWith('image/')) {
          continue;
        }
        // let size = file.size;
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setPicture(reader.result);
        };
        setFileObject(file);
        pictureObject = file;

        if (!ameraWebrtcClient) return;
      }
    }

    if (!!!pinParameter) return;
    if (!!!pictureObject) return;
    if (String(pinParameter).length < 6) return;

    getKeyFromFile(pictureObject, pinParameter)
      .then((secure) => {
        if (secure && secure.hexkey) {
          setCurrentKey(secure.hexkey);
        }
        if (secure && secure.key) {
          setKey(secure.key);
        }
      })
      .catch((error) => {
        console.error(
          'ONE2ONE Failed to fetch Key from API via HandlePicPinFiles'
        );
        console.error(error);
      });
  };

  const handlePinChange = (e) => {
    setPin(e.target.value);
    fetchKeyFromPicturePin(null, fileObject, e.target.value);
  };

  const handleEncrypt = async () => {
    if (!ameraWebrtcClient) return;
    const useCryptoOffset = true;
    try {
      await dispatch({
        type: 'SET_ENCRYPT_ENABLED',
        payload: { encryptEnabled: !encryptEnabled },
      });
    } catch (e) {
      console.log(e);
    }

    try {
      if (!encryptEnabled) {
        const currentCryptoKey = {
          keysize: 256,
          taglength: 128,
          fromkeyfile: false,
          key: currentKey,
          verbose: false,
        };
        currentCryptoKey.verbose = true;

        // this is the signaling server encryption object
        ameraWebrtcClient.ameraAes = new AmeraAES({ key: currentCryptoKey });
        ameraWebrtcClient.encryptEnabled = true;
        currentCryptoKey.verbose = false;
        currentCryptoKey.refreshCount = 1000;

        // hack for now.  make non-webcam start different
        if (memberInfo.email !== partner) {
          currentCryptoKey.send_counter = '000000000099900000000001';
        }

        // this is for the  worker encryption object, creates its own encryption objects
        ameraWebrtcClient.worker.postMessage({
          operation: 'setCryptoKey',
          assets_path: `${process.env.PUBLIC_URL}/`,
          currentCryptoKey,
          useCryptoOffset,
        });
      } else {
        ameraWebrtcClient.ameraAes = null;
        ameraWebrtcClient.encryptEnabled = false;
        const currentCryptoKey = {
          keysize: 0,
        }; // size 0 means turn off!!
        ameraWebrtcClient.worker.postMessage({
          operation: 'setCryptoKey',
          assets_path: `${process.env.PUBLIC_URL}/`,
          currentCryptoKey,
          useCryptoOffset,
        });
      }
      ameraWebrtcClient.sendUserMessage({
        type: 'toggleencryption',
        toggle: {
          enabled: !encryptEnabled,
        },
      });
    } catch (e) {
      console.log('socket error ocrrued');
    }
  };

  useEffect(() => {
    if (pictureRef.current) {
      let ps =
        // @ts-ignore
        pictureRef.current.offsetHeight > pictureRef.offsetWidth
          ? { width: '100%', height: 'auto' }
          : { width: 'auto', height: '100%' };
      setPictureStyle(ps);
    }
  }, [pictureRef]);

  // warn if E2EE not supported.  continue after acknowledged
  const supportsInsertableStreams =
    // @ts-ignore
    !!RTCRtpSender.prototype.createEncodedStreams;
  let supportsTransferableStreams = false;
  try {
    const stream = new ReadableStream();
    // @ts-ignore
    window.postMessage(stream, '*', [stream]);
    supportsTransferableStreams = true;
  } catch (e) {
    console.error('Transferable streams are not supported.');
  }

  let scary = '';
  if (!(supportsInsertableStreams && supportsTransferableStreams)) {
    scary = 'Your browser does not support Insertable Streams. ';

    if (adapter.browserDetails.browser === 'chrome') {
      scary +=
        '\n Try with Enable experimental "Web Platform" features enabled from chrome://flags.';
      scary += '\n applies to Brave, Vivaldi and other Chromium based browsers';
    }
  }

  useEffect(() => {
    // debugger;
    if (
      !(
        supportsInsertableStreams &&
        supportsTransferableStreams &&
        localStream
      ) ||
      !encryptionAvailable
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    encryptionAvailable,
    supportsInsertableStreams,
    supportsTransferableStreams,
    localStream,
  ]);

  return (
    <Grid item md={isInModal ? 12 : 4} sm={isInModal ? 12 : 4}>
      <Card className={classes.encryptionCard} variant="outlined">
        <CardContent className={classes.encryptionWrapper}>
          <div className={classes.encryptionImage}>
            {picture && (
              <img
                ref={pictureRef}
                src={picture}
                style={pictureStyle}
                alt="Preview"
              />
            )}
            {!picture && security && security.security_picture && (
              <img
                ref={pictureRef}
                src={`${apiUrl}${security.security_picture}`}
                style={pictureStyle}
                alt="Preview"
              />
            )}
          </div>
          <div className={classes.encryptionControlWrapper}>
            <div className={classes.encryptionImageKey}>
              <label htmlFor="aeskey">Key</label>
              <br />
              <input
                type="text"
                className="form-control form-control-sm"
                id="aeskey"
                value={currentKey}
                placeholder="current key"
                disabled
              />
              <hr />
            </div>
            <h5>Encryption Controls</h5>
            <div className={classes.controlGroup}>
              <div className={classes.fileInputWrapper}>
                <label>Picture</label>
                <input
                  className={classes.fileInput}
                  id="picfile"
                  type="file"
                  accept=".png, .jpg, .ppm, .jpeg"
                  // @ts-ignore
                  onChange={fetchKeyFromPicturePin}
                />
                <label htmlFor="picfile">
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    disabled={encryptEnabled}
                  >
                    Choose Picture
                  </Button>
                </label>
              </div>
              <div className={classes.userPinWrapper}>
                <label htmlFor="userpin">PIN</label>
                <input
                  type="text"
                  placeholder="enter pin"
                  value={pin}
                  onChange={handlePinChange}
                />
              </div>
            </div>
            <Tooltip title={scary}>
              <span>
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={handleEncrypt}
                  disabled={disabled}
                >
                  {!encryptEnabled
                    ? 'Create key and encrypt'
                    : 'Remove encryption'}
                </Button>
              </span>
            </Tooltip>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  partner: state.one2onevcall.partner,
  remoteStream: state.one2onevcall.remoteStream,
  callStarted: state.one2onevcall.callStarted,
  encryptEnabled: state.one2onevcall.encryptEnabled,
  memberInfo: state.member.memberInfo,
  encryptionAvailable: state.one2onevcall.encryptionAvailable,
  localStream: state.one2onevcall.localStream,
  // member_id: state.one2onevcall.member_id,
  security: state.security.security,
});

export default connect(mapStateToProps)(injectIntl(EncryptionController));
