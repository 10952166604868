import AmeraWebrtcClient from '../../utils/webcam/client';
import { GET_MEMBER_DATA_SUCCEEDED } from '../actionTypes/member';

const socketMiddleware = () => {
  const worker = new Worker(`${process.env.PUBLIC_URL}/worker.js`);
  const client = new AmeraWebrtcClient('contact', worker);

  const connect = async (user) => {
    client.connectWebsocket(user);
  };
  // the middleware part of this function
  return (store) => (next) => (action) => {
    const payload = action.payload;
    switch (action.type) {
      case GET_MEMBER_DATA_SUCCEEDED:
        connect({
          username: payload.username,
          member_id: payload.member_id,
          first_name: payload.first_name,
          last_name: payload.last_name,
        });
        next(action);
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
