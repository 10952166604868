import {
  STROPHE_CONNECTION_STATUS,
  SET_CHAT_CONVERSATION_ID,
  SET_GROUP_CONVERSATION_ID,
  ADD_CHAT_MESSAGE,
  ADD_GROUP_MESSAGE,
  LOAD_ROSTER_ITEMS,
  UPDATE_GROUP_ROSTERS,
  SET_GROUP_ROSTER_STATUS,
  DELETE_GROUP_CHAT_ROOM,
  DECRYPT_MESSAGE,
  DECRYPT_GROUP_MESSAGE,
  SET_CHAT_UNREAD_MESSAGE_COUNT,
  CHAT_RESET,
  SET_MESSAGE_AS_READ,
} from '../actionTypes/chat';
import { APP_RESET } from '../actionTypes/app';

import { orderBy } from 'lodash';

const initialState = {
  connectionStatus: null,
  rosterItems: [],
  chatConversations: [],
  groupConversations: [],
  selectedChatID: null,
  selectedGroupID: null,
};

const chat = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case APP_RESET:
      return initialState;
    case CHAT_RESET:
      return {
        ...initialState,
        connectionStatus: state.connectionStatus,
      };
    case STROPHE_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: payload.status,
      };
    case SET_CHAT_CONVERSATION_ID:
      return {
        ...state,
        selectedChatID: payload.ID,
        chatConversations: createChatConversationIfNotExist(
          state.chatConversations,
          payload.ID
        ),
      };
    case SET_GROUP_CONVERSATION_ID:
      return {
        ...state,
        selectedGroupID: payload.ID,
        groupConversations: createGroupConversationIfNotExist(
          state.groupConversations,
          payload.ID
        ),
      };
    case ADD_CHAT_MESSAGE:
      return {
        ...state,
        chatConversations: processChatConversations(
          state.chatConversations,
          payload
        ),
      };
    case LOAD_ROSTER_ITEMS:
      return {
        ...state,
        rosterItems: payload.rosterItems,
      };
    case ADD_GROUP_MESSAGE:
      return {
        ...state,
        groupConversations: processGroupConversations(
          state.groupConversations,
          payload
        ),
      };
    case UPDATE_GROUP_ROSTERS:
      return {
        ...state,
        groupConversations:
          // {...new Map(
          state.groupConversations.map((g) => {
            if (g['chatID'] === payload.groupID) {
              payload.groupRosters.forEach((pr) => {
                if (
                  g.groupRosters.findIndex(
                    (x) => x.rosterID === pr.rosterID
                  ) === -1
                ) {
                  g.groupRosters.push(pr);
                }
              });
            }
            return g;
          }),
        // .map((r) => [r['jid'], r])).values()}
      };
    case SET_GROUP_ROSTER_STATUS:
      return {
        ...state,
        groupConversations: updateGroupRosterStatus(
          state.groupConversations,
          payload
        ),
      };
    case DELETE_GROUP_CHAT_ROOM:
      return {
        ...state,
        groupConversations: deleteGroupChatRoomMessages(
          state.groupConversations,
          payload
        ),
      };
    case DECRYPT_MESSAGE:
      return {
        ...state,
        chatConversations: payload,
      };
    case DECRYPT_GROUP_MESSAGE:
      return {
        ...state,
        groupConversations: payload,
      };
    case SET_CHAT_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        chatConversations: setChatUnreadCount(state.chatConversations, payload),
      };
    case SET_MESSAGE_AS_READ:
      return {
        ...state,
        chatConversations: setMessageAsRead(state.chatConversations, payload),
      };
    default:
      return state;
  }
};

const setMessageAsRead = (conversations, { chatID, messageID }) => {
  const newConversations = conversations.map((c) => {
    if (c.chatID === chatID) {
      const newMessages = c.messages.map((m) => {
        if (m.id === messageID) {
          return {
            ...m,
            isRead: true,
          };
        }
        return m;
      });
      return {
        ...c,
        messages: newMessages,
      };
    }
    return c;
  });
  return newConversations;
};

const processChatConversations = (conversations, payload) => {
  const { messageObj } = payload,
    { chatID } = messageObj;

  let newConversations = createChatConversationIfNotExist(
    conversations,
    chatID
  );

  newConversations = newConversations.map((c) => {
    if (c.chatID === messageObj.chatID) {
      let messages = [...c.messages];
      const msgIndex = messages.findIndex((m) => m.id === messageObj.id);
      if (msgIndex === -1) {
        //insert msg
        if (payload.direction === 'forward')
          messages = [...messages, { ...messageObj }];
        else messages = [{ ...messageObj }, ...messages];
      }
      // TODO: not sure why this logic is required

      // else if (messageObj.archived) {
      //   // if msg already exist (received as recent msg)
      //   // and then again it's loaded from archived then change its position at the end of array
      //   messages.push(messages.splice(msgIndex, 1)[0]);
      // }
      else {
        //update msg
        messages[msgIndex] = { ...messageObj };
      }
      c.messages = [...messages];
    }
    return { ...c };
  });

  return orderBy(
    newConversations,
    (o) => o.messages[o.messages.length - 1]?.stamp || '',
    'desc'
  );
};

const processGroupConversations = (conversations, payload) => {
  const { chatID, messageObj } = payload;

  let newConversations = createGroupConversationIfNotExist(
    conversations,
    chatID
  );

  newConversations = newConversations.map((c) => {
    if (c.chatID === messageObj.chatID) {
      let messages = [...c.messages];
      const msgIndex = messages.findIndex((m) => m.id === messageObj.id);
      if (msgIndex === -1) {
        //insert msg
        if (payload.direction === 'forward')
          messages = [...messages, { ...messageObj }];
        else messages = [{ ...messageObj }, ...messages];
      } else {
        //update msg
        messages[msgIndex] = { ...messageObj };
      }
      c.messages = [...messages];
    }
    return { ...c };
  });
  return orderBy(
    newConversations,
    (o) => o.messages[o.messages.length - 1]?.stamp || '',
    'desc'
  );
};

const createChatConversationIfNotExist = (conversations, chatID) => {
  let newConversations = [...conversations];
  if (chatID && newConversations.findIndex((o) => o.chatID === chatID) === -1) {
    let conversation = {};
    conversation['chatID'] = chatID;
    conversation['unreadCount'] = 0;
    conversation['messages'] = [];
    newConversations.push(conversation);
  }
  return newConversations;
};

const createGroupConversationIfNotExist = (conversations, chatID) => {
  let newConversations = [...conversations];
  if (chatID && newConversations.findIndex((o) => o.chatID === chatID) === -1) {
    let conversation = {};
    conversation['chatID'] = chatID;
    conversation['groupRosters'] = [];
    conversation['messages'] = [];

    newConversations.push(conversation);
  }
  return newConversations;
};

const updateGroupRosterStatus = (conversations, payload) => {
  const { groupID, rosterID, status } = payload;
  return conversations.map((g) => {
    if (g['chatID'] === groupID) {
      g.groupRosters.map((r) => {
        if (r.rosterID === rosterID) {
          r.status = status;
        }
        return r;
      });
    }
    return g;
  });
};

const setChatUnreadCount = (conversations, { chatID, unreadCount }) => {
  return conversations.map((c) => {
    if (parseInt(c.chatID) === parseInt(chatID)) {
      c.unreadCount = unreadCount;
    }
    return c;
  });
};

const deleteGroupChatRoomMessages = (groupConversations, payload) => {
  // show delete msg and set contacts offline status
  // this will sho only if member currently in room
  const { chatID, message } = payload;
  return groupConversations.map((g) => {
    if (g['chatID'] === chatID) {
      g.isDestroyed = true;
      g.groupRosters.map((c) => {
        c.status = 'offline';
        return c;
      });
      g.destroyMessage = message;
    }
    return g;
  });
};

export default chat;
