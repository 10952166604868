const logPingMessage = (status) => (stanza) => {
  // console.debug(`PING: ${status} Stanza`);
  // console.debug(stanza);
};
export const ping = (connection) => {
  connection.ping.ping(
    connection.jid,
    logPingMessage('Success'),
    logPingMessage('Error')
  );
};
