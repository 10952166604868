import React, { useCallback, useMemo } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Strophe } from 'strophe.js';

// Pages
import SignUp from './pages/SignUp';
import Registration from './pages/Registration';
import Login from './pages/Login';
import ForgotPassword from './pages/PasswordUpdate/ForgotPassword';
import ChangePassword from './pages/PasswordUpdate/ChangePassword';
import Dashboard from './pages/Dashboard';
import FileShare from './pages/FileShare';
import Contact from './pages/Contact';
import Group from './pages/Group';
import ContactChat from './pages/Chat/ContactChat';
import GroupChat from './pages/Chat/GroupChat';
import Activity from './pages/Activity';
import Home from './pages/Home';
import Email from './pages/Email';
import One2OneVCall from './pages/One2OneVCall';
import VideoCall from './pages/VideoCall';
import ProjectManagements from './pages/ProjectManagement';
import Layout from './pages/Sections/Layout';
import MediaStreaming from './pages/MediaStreaming';
import MediaScheduler from './pages/MediaStreaming/Scheduler';
import Billing from './pages/Billing';

// import {
// getMember,
// getTitleList,
// getDepartmentList,
// getCountryList,
// getTZList,
// getRoleList,
// getPageSettings,
// getSkills,
// getIndustries,
// getTermsAndConditions,
// } from './redux/actions/member';

import Calendar from './pages/Calendar';
import Forum from './pages/Forum';
import { nonBrowserFeatureState } from './utils/non-browser-state';
import ResetPassword from './pages/PasswordUpdate/ResetPassword';
import useQuery from './hooks/useQuery';
import Countdown from './components/Countdown';

import { messages } from './pages/Sections/Sidebar/navItems';
// import { setBadgeCount } from './redux/actions/mail';

const MainRouter = (props) => {
  const history = useHistory();

  const authRoutes = [
    {
      path: `${process.env.PUBLIC_URL}/dashboard`,
      component: Dashboard,
      title: messages.dashboard,
    },
    {
      path: `${process.env.PUBLIC_URL}/media-streaming`,
      component: MediaStreaming,
      title: messages.mediaStreaming,
    },
    {
      path: `${process.env.PUBLIC_URL}/media-streaming-scheduler`,
      component: MediaScheduler,
      title: messages.mediaScheduler,
    },
    {
      path: `${process.env.PUBLIC_URL}/fileshare`,
      component: FileShare,
      title: messages.fileSharing,
    },
    {
      path: `${process.env.PUBLIC_URL}/projects`,
      component: ProjectManagements,
      title: messages.projectManagement,
    },
    {
      path: `${process.env.PUBLIC_URL}/contacts/call/video/:room/conference`,
      component: VideoCall,
      title: messages.groupCall,
    },
    {
      path: `${process.env.PUBLIC_URL}/contacts/call/video/:room`,
      component: One2OneVCall,
      title: messages.contactCall,
    },
    {
      path: `${process.env.PUBLIC_URL}/groups/call/video/:room`,
      component: VideoCall,
      title: messages.groupCall,
    },
    {
      path: `${process.env.PUBLIC_URL}/contacts/chat`,
      component: ContactChat,
      title: messages.chat,
    },
    {
      path: `${process.env.PUBLIC_URL}/groups/chat/:room`,
      component: GroupChat,
      title: messages.groupChat,
    },
    {
      path: `${process.env.PUBLIC_URL}/groups/:group_id/forum`,
      component: Forum,
      title: messages.groupForums,
    },
    {
      path: `${process.env.PUBLIC_URL}/contacts`,
      component: Contact,
      title: messages.contacts,
    },
    {
      path: `${process.env.PUBLIC_URL}/groups`,
      component: Group,
      title: messages.group,
    },
    {
      path: `${process.env.PUBLIC_URL}/calendar`,
      component: () => (
        <Calendar
          {...props}
          title={messages.calendar}
          group_id={query.get('group_id')}
          member_ids={query.get('member_ids')}
        />
      ),
    },
    {
      path: `${process.env.PUBLIC_URL}/statistic`,
      component: Activity,
      title: messages.historyAndAnalytics,
    },
    {
      path: `${process.env.PUBLIC_URL}/mail`,
      component: Email,
      title: messages.mail,
    },
    {
      path: `${process.env.PUBLIC_URL}/billing`,
      component: Billing,
      title: messages.transaction,
    },
    {
      path: '/',
      component: Layout,
    },
  ];

  // useEffect(() => {
  //   // Load member page default settings
  //   dispatch(getPageSettings());
  // }, [dispatch]);

  // useEffect(() =>
  //   // Load the job title list to from back-end
  //   {
  //     dispatch(getTitleList());
  //   }, [dispatch]);

  // useEffect(() =>
  //   // Load the departments list
  //   {
  //     dispatch(getDepartmentList());
  //   }, [dispatch]);

  // useEffect(() =>
  //   // Load the active country list
  //   {
  //     dispatch(getCountryList());
  //   }, [dispatch]);

  // useEffect(() => {
  //   // Load timezones data
  //   dispatch(getTZList());
  // }, [dispatch]);

  // useEffect(() => {
  //   // Load roles list
  //   dispatch(getRoleList());
  // }, [dispatch]);

  // useEffect(() => dispatch(getSkills()), [dispatch]);

  // useEffect(() => dispatch(getIndustries()), [dispatch]);
  // // useEffect(() =>
  // //   // Load the terms and conditions
  // //   {
  // //     dispatch(getTermsAndConditions());
  // //   }, [dispatch]);

  // useEffect(() => {
  //   dispatch(setBadgeCount());
  // }, [dispatch]);

  // useEffect(() => {
  //   console.log('validating session')
  //   dispatch(getMember());
  // }, [dispatch]);

  // const mailBadgeCountLoading = useSelector(
  //   (state) => state.mail.mailBadgeCountLoading
  // );

  // useEffect(() => {
  //   const unregister = history.listen(() => {
  //     if (mailBadgeCountLoading === false) {
  //       dispatch(setBadgeCount());
  //     }
  //   });
  //   return () => {
  //     unregister();
  //   };
  // }, [dispatch, history, mailBadgeCountLoading]);

  const redirectTo = nonBrowserFeatureState
    ? `${process.env.PUBLIC_URL}/login`
    : `${process.env.PUBLIC_URL}/home`;

  let query = useQuery();

  let matchNonAuthRoutes = [
    '/login',
    '/home',
    '/signup',
    '/registration',
    '/settings',
  ].filter((route) => history.location.pathname.startsWith(route));
  let showInPage = matchNonAuthRoutes.length === 0;

  const { member, showCountdown, facial_recognition, faceMatched } = props;
  // const { app, loadingSession, loginAttempt, loginCompleted } = props

  const end = useMemo(() => {
    if (member && member.expiration_date !== undefined) {
      return new Date(member.expiration_date);
    }
    return new Date();
  }, [member]);

  if (!member || !member.expiration_date) {
    showInPage = false;
  }

  const AuthRoute = useCallback(
    ({ component: Component, ...rest }) => {
      const now = new Date();
      console.log('member', member);
      console.log('end', end);
      console.log('now', now);
      return (
        <Route
          {...rest}
          render={(props) =>
            member && member.session_id && end > now ? (
              // &&
              // (!facial_recognition || (facial_recognition && faceMatched))
              <Component {...{ ...props, ...rest }} />
            ) : (
              <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            )
          }
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member, facial_recognition, faceMatched, end]
  );

  return (
    <>
      <Switch>
        {/* PUBLIC PAGES */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/signup`}
          component={SignUp}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/registration/:inviteKey?`}
          component={Registration}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login/forgot`}
          component={ForgotPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login/reset/:forgot_key`}
          component={ResetPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/settings/password`}
          component={ChangePassword}
        />
        <Redirect from={`${process.env.PUBLIC_URL}/`} to={redirectTo} exact />

        {/* AUTH PAGES */}
        {authRoutes.map(({ path, component, title }, idx) => (
          <AuthRoute
            key={idx}
            path={path}
            component={component}
            title={title}
          />
        ))}
      </Switch>
      {showInPage && <Countdown end={end} show={showCountdown} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  member: state.member.member,
  showCountdown: state.member.showCountdown,
  facial_recognition: state.member.memberInfo.facial_recognition,
  faceMatched: state.member.faceMatched,
  app: state.app,
  loadingSession: state.member.loadingSession,
  loginAttempt: state.member.loginAttempt,
  loginCompleted: state.member.loginCompleted,
});

export default connect(mapStateToProps)(MainRouter);
