import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import MaterialAvatar from '../../../../components/MaterialAvatar';

function ConversationEmpty({
  isGroupChat = false,
  textPrimary,
  textSecondary = '',
  memberAvatarURL = undefined,
  memberFirstName = undefined,
  memberLastName = undefined,
  memberID = undefined,
  contactAvatarURL = undefined,
  contactFirstName = undefined,
  contactLastName = undefined,
  contactMemberID = undefined,
}) {
  return (
    <div id="no-coversation-layout">
      <div id="no-conversation-content">
        <h2>{textPrimary || 'No Conversations'}</h2>
        <AvatarGroup max={3} id="no-conversation-avatar-group">
          <MaterialAvatar
            src={memberAvatarURL}
            firstName={memberFirstName}
            lastName={memberLastName}
            isBasicAvatar={true}
            member_id={memberID}
          />
          {contactAvatarURL && (
            <MaterialAvatar
              src={contactAvatarURL}
              firstName={contactFirstName}
              lastName={contactLastName}
              isBasicAvatar={true}
              member_id={contactMemberID}
            />
          )}
          {isGroupChat && (
            <MaterialAvatar
              src=""
              firstName=""
              lastName=""
              isBasicAvatar={true}
              member_id={undefined}
            />
          )}
        </AvatarGroup>
        <p>{textSecondary}</p>
      </div>
    </div>
  );
}

export default ConversationEmpty;
