import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import SVG from '../../../components/Icons/SVG';
// import CreateNewFile from '../../FileShare/CreateFile';
import ExpandedCreateGroupModal from '../../Group/ExpandedCreateGroupModal';
// import CreateGroup from '../../Group/CreateGroup';
// import { getMembers } from '../../../redux/actions/member';
// import DecryptFile from '../../FileShare/DecryptFile';
import { getCompose } from '../../../redux/actions/event';
import EventCreateModal from '../../../components/Modal/EventModals/EventCreateModal';

const SubMenu = (props) => {
  const dispatch = useDispatch();
  // const [showNewFileModal, setShowNewFileModal] = useState(false);
  // const [showDecryptFileModal, setShowDecryptFileModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [composeOpen, setComposeOpen] = useState(false);

  // const {dispatch} = props;
  const history = useHistory();
  useEffect(() => {
    const path = props.path;
    const navItemIndex = props.menuItems.findIndex((item) =>
      path.includes(item.to)
    );
    if (navItemIndex >= 0) {
      props.setActiveMenu(navItemIndex);
    }
  }, [props]);

  useEffect(() => {
    if (!props.compose) setComposeOpen(false);
    console.log(props.compose);
  }, [props.compose]);

  const primaryBtn = (props) => {
    switch (props.id) {
      // case 'fileShare':
      //   setShowNewFileModal(true);
      //   break;
      case 'groups':
        // dispatch(getMembers({search_key: ''}));
        // setShowCreateGroupModal(true);
        history.push('new-group');
        break;
      case 'calendar':
        setShowCreateEventModal(true);
        break;
      default:
        break;
    }
  };

  // const secondaryBtn = (props) => {
  //   switch (props.id) {
  //     // case 'fileShare':
  //     //   setShowDecryptFileModal(true);
  //     //   break;
  //     default:
  //       break;
  //   }
  // };

  return props.menuItems.length > 0 ? (
    <div className="sub-menu">
      <div className="item-list">
        {props.btnLabel && (
          <div className="d-flex justify-content-center">
            {props.id !== 'fileShare' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => primaryBtn(props)}
              >
                {props.btnLabel}
              </Button>
            )}
            {/* <CreateNewFile
              show={showNewFileModal}
              close={() => setShowNewFileModal(false)}
            ></CreateNewFile> */}
            {/* <CreateGroup
              show={showCreateGroupModal}
              close={() => setShowCreateGroupModal(false)}
            >
            </CreateGroup> */}
            <ExpandedCreateGroupModal
              show={showCreateGroupModal}
              close={() => setShowCreateGroupModal(false)}
            />
            {showCreateEventModal && (
              <EventCreateModal
                show={showCreateEventModal}
                onClose={() => setShowCreateEventModal(false)}
                setEventModalShow={setShowCreateEventModal}
              />
            )}
          </div>
        )}
        {/* {props.secondBtnLabel && (
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: '10px' }}
          >
            <Button className="btn" onClick={() => secondaryBtn(props)}>
              {props.secondBtnLabel}
            </Button>
            <DecryptFile
              show={showDecryptFileModal}
              close={() => setShowDecryptFileModal(false)}
            ></DecryptFile>
          </div>
        )} */}
        <ul>
          {props.menuItems.map((item, index) => (
            <li
              className={classNames({
                active:
                  props.activeMenu === (composeOpen ? '' : index) ||
                  (composeOpen && item.compose),
              })}
              key={`submenu_${item.id}`}
              onClick={(e) => {
                history.push(`${process.env.PUBLIC_URL}${item.to}`);
                props.setActiveMenu(index);
                setComposeOpen(false);
                dispatch(getCompose(false));
              }}
            >
              <div className="row">
                <div className="col-2">
                  <SVG name={item.icon} />
                </div>
                <div
                  className={classNames('label', {
                    [`col-8`]: props.hasCount,
                    [`col-10`]: !props.hasCount,
                  })}
                >
                  {item.external ? (
                    <a
                      href={`${item.to}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  ) : !item.compose ? (
                    <Link
                      to={`${process.env.PUBLIC_URL}${item.to}`}
                      dangerouslySetInnerHTML={{ __html: item.label }}
                    >
                      {/* {item.label} */}
                    </Link>
                  ) : (
                    item.label
                  )}
                  {/* {item.compose ? dispatch(getCompose(composeOpen)) : ''} */}
                </div>
                {item.count ? (
                  <div className="col-2">
                    <div
                      className="count"
                      style={{ backgroundColor: `${item.color}` }}
                    >
                      {item.count}
                    </div>
                  </div>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {props.filterItems && props.filterItems.length > 0 && (
        <hr className="sub-menu-divider" />
      )}
      <div className="item-list">
        <ul>
          {props.filterItems &&
            props.filterItems.map((item, index) => (
              <li key={`submenu_${item.id}`}>
                <div className="row">
                  <div className="col-3">
                    <div
                      className="group-filter"
                      style={{ borderColor: item.color }}
                    />
                  </div>
                  <div className="col-6">{item.label}</div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  ) : null;
};

SubMenu.propTypes = {
  activeMenu: PropTypes.number.isRequired,
  btnLabel: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  filterItems: PropTypes.array,
  setActiveMenu: PropTypes.func,
  hasCount: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  compose: state.event.compose,
  // any props you need else
});

export default connect(mapStateToProps)(SubMenu);
