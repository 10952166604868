import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Strophe } from 'strophe.js';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MaterialAvatar from '../../components/MaterialAvatar';
import AmeraPagination from '../../components/AmeraPagination';

import { addMemberToContact } from '../../redux/actions/contact';
import { subscribeMembers } from '../../redux/actions/chat';
import {
  displayableFullName,
  getContactDevice,
  getContactLocation,
} from '../../utils/contact';

const headCells = [
  {
    id: 'member_name',
    label: 'Name',
    minWidth: 164,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company',
    label: 'Company',
    minWidth: 80,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 80,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 80,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cell_phone',
    label: 'Cell Phone Number',
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 16px',
    justifyContent: 'space-between',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  sortTool: {
    marginBottom: '16px',
    minWidth: '120px',
  },
  actionBtns: {
    display: 'flex',
  },
  addContactsBtn: {
    borderRadius: 25,
    minWidth: 145,
    // marginRight: 10,
  },
  contactsBtn: {
    borderRadius: 25,
    minWidth: 145,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { dispatch, numSelected, isGlobalContacts, handleLoadContacts } = props;

  // @ts-ignore
  const selectedMembersData = useSelector((s) => s.contact?.contactMembers)
    ?.filter((c1) => numSelected.some((c2) => c1.id === c2))
    .map((c) => ({
      id: c.id,
      nick: displayableFullName(c),
    }));
  // @ts-ignore
  const connectionStatus = useSelector((s) => s.chat.connectionStatus);

  const handleAddMemberToContact = () => {
    const formData = new FormData();
    formData.set('member_id_list', numSelected);
    formData.set('type', 'add-contact');
    dispatch(addMemberToContact(formData));

    dispatch(subscribeMembers(selectedMembersData));
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected.length > 0,
      })}
    >
      {numSelected.length > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected.length} {numSelected.length > 1 ? 'members' : 'member'}{' '}
          selected
        </Typography>
      ) : isGlobalContacts ? (
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Please select member(s) to request to be added to your contacts
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Contacts
        </Typography>
      )}

      {numSelected.length > 0 ? (
        isGlobalContacts ? (
          <Tooltip title="Add to my contacts">
            <IconButton
              disabled={connectionStatus !== Strophe.Status.CONNECTED}
              aria-label="Add to my contacts"
              onClick={handleAddMemberToContact}
            >
              Add to my contacts
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Toolbar
            className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            {/* <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} contacts selected
            </Typography>
            <Tooltip title="Schedule event" placement="top">
              <IconButton
                aria-label="add"
                onClick={() => props.handleScheduleEvent(selectedContacts)}
              >
                <EventIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Create Group" placement="top">
              <IconButton
                aria-label="add"
                onClick={() => props.handleCreateGroup(selectedContacts)}
              >
                <GroupAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send email" placement="top">
              <IconButton
                aria-label="add"
                onClick={() =>
                  props.setCompose({
                    open: true,
                    initialState: { receivers: [...selectedContacts] },
                  })
                }
              >
                <EmailOutlinedIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip
              title={`Delete Item${numSelected > 1 ? 's' : ''}`}
              placement="top"
            >
              <IconButton
                aria-label="delete"
                onClick={() => props.handleDeleteContacts()}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        )
      ) : (
        <ButtonGroup
          disableRipple
          disableElevation
          variant="contained"
          className={classes.actionBtns}
        >
          <Button
            className={classes.addContactsBtn}
            color={isGlobalContacts ? 'primary' : ''}
            onClick={() => handleLoadContacts(true)}
          >
            Add Contacts
          </Button>
          <Button
            className={classes.contactsBtn}
            color={!isGlobalContacts ? 'primary' : ''}
            onClick={() => handleLoadContacts(false)}
          >
            Contacts
          </Button>
        </ButtonGroup>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  dispatch: PropTypes.func,
  numSelected: PropTypes.arrayOf(PropTypes.number),
  members: PropTypes.arrayOf(PropTypes.object),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    overflow: 'hidden',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ContactMembersTable = (props) => {
  const dispatch = useDispatch();
  const {
    isGlobalContacts,
    setIsGlobalContacts,
    loading,
    totalCount,
    contactMembers,
    contacts,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    pageSize,
    pageNumber,
    handleChangePage,
    handleChangeRowsPerPage,
    loadSortedContacts,
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const [contactsData, setContactsData] = useState([]);

  useEffect(() => {
    loadSortedContacts(null, null, null, null, null, null, true);
    setIsGlobalContacts(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isGlobalContacts) {
      setContactsData(contactMembers);
    } else {
      setContactsData(contacts);
    }
    setSelected([]);
  }, [isGlobalContacts, contacts, contactMembers]);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedContacts(
      property,
      newOrder,
      null,
      null,
      null,
      null,
      isGlobalContacts
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contactsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleLoadContacts = (isGlobal) => {
    loadSortedContacts(null, null, null, null, 0, null, isGlobal);
    setIsGlobalContacts(isGlobal);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className="contact-table">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          loading={loading}
          numSelected={selected}
          dispatch={dispatch}
          orderBy={orderBy}
          setOrder={(order) => setOrder(order)}
          isGlobalContacts={isGlobalContacts}
          setIsGlobalContacts={setIsGlobalContacts}
          handleLoadContacts={handleLoadContacts}
          handleDeleteContacts={() => {
            props.deleteContactsFunc(
              selected.map((id) => {
                return { id };
              })
            ); //structruing a/c to func
            setSelected([]);
          }}
        />
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={contactsData.length}
            />
            {!loading && (
              <TableBody>
                {contactsData.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const locationInfo = getContactLocation(row, 'home');
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="contact-member-name"
                      >
                        <div>
                          <MaterialAvatar
                            member_id={row.id}
                            src={row.amera_avatar_url}
                            firstName={row.first_name}
                            lastName={row.last_name}
                            classes="contact"
                          />
                        </div>
                        <p>{displayableFullName(row)}</p>
                      </TableCell>
                      <TableCell align="left">
                        {!!row.company_membership
                          ? row.company_membership.name
                          : row.company}
                      </TableCell>
                      <TableCell align="left">{row.title || ''}</TableCell>
                      <TableCell align="left">{row.department || ''}</TableCell>
                      <TableCell align="left">
                        {(locationInfo && locationInfo.locality) || ''}
                      </TableCell>
                      <TableCell align="left">
                        {(locationInfo && locationInfo.country_name) || ''}
                      </TableCell>
                      <TableCell align="left">{row.email || ''}</TableCell>
                      <TableCell align="left">
                        {getContactDevice(row, 'Cell phone') || 'Not Available'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!loading && (
          <AmeraPagination
            itemTypeLabel="Items"
            totalCount={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={pageSize}
            page={pageNumber}
          />
        )}
      </Paper>
    </div>
  );
};

export default ContactMembersTable;
