import React from 'react';
import { makeStyles } from '@material-ui/core';
import AMERAIoTLogo from '../../../assets/img/amera-iot.png';
import AMERAIoTLogoShield from '../../../assets/img/amera-iot-shield.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#707070',
    height: '54px',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // textAlign: 'center'
  },
  poweredBy: {
    float: 'right',
    display: 'flex',
    justifyContent: 'right',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'left',
    backgroundColor: '#fff',
  },
  iotLogo: {
    height: 55,
    margin: 10,
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.logoContainer}>
        <img
          src={AMERAIoTLogo}
          alt="AMERA IoT™ Inc."
          className={classes.iotLogo}
        />
      </div>
      <div className={classes.root}>
        <span>&nbsp;</span>
        <span>
          © Copyright 2021 AMERA IoT&#8482; Inc. - All Rights Reserved
        </span>
        <div className={classes.poweredBy}>
          <i>Powered by</i>
          <img
            src={AMERAIoTLogoShield}
            alt="AMERA IoT™ Inc."
            className="iot-shield-icon"
          />
          AMERA IoT&#8482;
        </div>
      </div>
    </>
  );
};

export default Footer;
