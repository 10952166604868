import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Strophe } from 'strophe.js';

import Layout from '../../Sections/Layout';
import ChatsPanel from './ChatsPanel';
import ChatMessages from '../shared/ChatMessages/index';
import ChatForm from '../shared/ChatForm';

import useQuery from '../../../hooks/useQuery';
import usePrevious from '../../../hooks/usePrevious';

import {
  setChatConversationID,
  sendChatMessage,
} from '../../../redux/actions/chat';
import { enableE2EEncryption } from '../../../redux/actions/security';
import { getContactOnlineStatus } from '../../../utils/strophe/user';
import { getMemberContactChatArchive } from '../../../redux/actions/member';

const ContactChat = (props) => {
  // @ts-ignore
  const contacts = useSelector((store) => store.contact.contacts || []);
  // @ts-ignore
  const selectedChatID = useSelector((store) => store.chat.selectedChatID);
  // @ts-ignore
  const connectionStatus = useSelector((store) => store.chat.connectionStatus);
  // @ts-ignore
  const securityContactId = useSelector((store) => store.security.contact_id);
  // @ts-ignore
  const rosterItems = useSelector((s) => s?.chat?.rosterItems);

  const contactInfo = contacts.find(
    (c) => c.contact_member_id === parseInt(selectedChatID)
  );

  const dispatch = useDispatch();
  const messagesEnfRef = useRef(<div></div>);
  const chatID = useQuery().get('chatID') || null;
  const prevChatID = usePrevious(chatID);

  const scrollToBottom = () => {
    try {
      // @ts-ignore
      messagesEnfRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    } catch (e) {
      console.debug(' No Scrolling Necessary ');
      console.error(e);
    }
  };

  const handleSendMessage = (messageText) => {
    dispatch(sendChatMessage(selectedChatID, messageText));
    setTimeout(() => scrollToBottom(), 100);
  };

  React.useEffect(() => {
    if (chatID && prevChatID !== chatID) {
      if (parseInt(chatID) !== securityContactId) {
        dispatch(enableE2EEncryption(false));
      }
      dispatch(setChatConversationID(chatID));
    }
  }, [dispatch, prevChatID, chatID, securityContactId, contacts]);

  React.useEffect(() => {
    return () => {
      dispatch(enableE2EEncryption(false));
    };
  }, [dispatch]);

  React.useEffect(() => {
    return () => {
      dispatch(enableE2EEncryption(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledChatForm = React.useMemo(
    () => connectionStatus !== Strophe.Status.CONNECTED,
    [connectionStatus]
  );

  const disabledFileInput = React.useMemo(
    () => getContactOnlineStatus(contactInfo, rosterItems) !== 'online',
    [contactInfo, rosterItems]
  );

  const e2eEncryptionEnabled = useSelector(
    (state) => state.security.e2eEncryptionEnabled
  );

  React.useEffect(() => {
    dispatch(getMemberContactChatArchive(connectionStatus));
  }, [dispatch, connectionStatus, e2eEncryptionEnabled]);

  if (connectionStatus !== Strophe.Status.CONNECTED) return null;

  return (
    <Layout {...props}>
      <div className="chat-section">
        <div className="messages-container">
          <ChatMessages messagesEnfRef={messagesEnfRef} />
          {selectedChatID ? (
            <ChatForm
              disabled={disabledChatForm}
              disableFileInput={disabledFileInput}
              handleSendMessage={handleSendMessage}
              selectedChatID={selectedChatID}
              contactID={selectedChatID}
              contactFirstName={contactInfo?.first_name || ''}
              contactLastName={contactInfo?.last_name || ''}
              type="contact"
            />
          ) : (
            <div className="chat-form" />
          )}
        </div>
        <div className="chats-panel">
          <ChatsPanel />
        </div>
      </div>
    </Layout>
  );
};

export default ContactChat;
