import { Strophe, $iq } from 'strophe.js';
//here list of all other plugins https://github.com/strophe/strophejs-plugins/
import { xmlToJson } from './chat';
import { getBareJID } from './user';
import { GROUP_ROLES } from '../group';

export const createRoom = async (connection, room, username, members) => {
  try {
    try {
      const roomInfo = await getRoomInfo(connection, room);
      console.error('room already exist', roomInfo);
    } catch (stanza) {
      // call muc join func to create a room
      connection.muc.join(room, username);
    }
    // configure room
    try {
      await configureRoom(connection, room);
    } catch (e) {
      console.debug('Error Configuring Room');
      console.error(e);
    }

    // set affiliation if member is admin
    members.forEach((m) => {
      const { bareJID, userType } = m;

      // send group invite // not necessary
      connection.muc.invite(room, bareJID, 'Hi, please join group.');

      // if contact is admin then set it as admin of room
      if (userType === GROUP_ROLES.owner) {
        modifyMemberAffiliation(connection, room, bareJID, 'admin');
      }
    });

    // leave muc room after config
    leaveRoom(connection, room, username);
  } catch (err) {
    console.log('something went wrong when creating room', err);
  }
};

export const joinRoom = async (
  connection,
  room,
  username,
  messageHandler = null,
  presenceHandler = null,
  rosterHandler = null
) => {
  try {
    // check if room exist before join
    await getRoomInfo(connection, room);
    connection.muc.join(
      room,
      username,
      messageHandler,
      presenceHandler,
      rosterHandler,
      null,
      { maxstanzas: 0 }
    );
  } catch (stanza) {
    console.log('room not found', stanza);
  }
};

export const leaveRoom = (connection, room, username) => {
  const exitMessage = `${username} left`;
  console.debug(`LeaveRoom exit message: ${exitMessage}`);
  return new Promise((resolve) => {
    connection.muc.leave(
      room,
      username,
      (stanza) => {
        resolve(stanza);
        return true;
      },
      exitMessage
    );
  });
};

export const configureRoom = (connection, room) => {
  const config = {
    'muc#roomconfig_publicroom': '0',
    'muc#roomconfig_semianonymous': '0',
    'muc#roomconfig_membersonly': '1',
    'muc#roomconfig_presencebroadcast': 'anyone',
    'muc#roomconfig_whois': 'anyone',
    'muc#roomconfig_persistentroom': '1',
  };
  return new Promise((resolve, reject) => {
    connection.muc.createConfiguredRoom(
      room,
      config,
      (stanza) => {
        console.debug('Room Configuration response');
        console.debug(stanza);
        resolve(stanza);
        return true;
      },
      (stanza) => {
        console.debug('Room Configuration error');
        console.debug(stanza);
        reject(stanza);
        return true;
      }
    );
  });
};

export const getRoomList = (connection) => {
  return new Promise((resolve, reject) => {
    connection.muc.listRooms(
      Strophe.getDomainFromJid(connection.jid),
      (stanza) => {
        console.debug('GetRoomList response', stanza);
        resolve(stanza);
        return true;
      },
      (stanza) => {
        console.debug('GetRoomList response error', stanza);
        reject(stanza);
        return true;
      }
    );
  });
};

export const getRoomInfo = (connection, room) => {
  const roomInfoIq = $iq({
    type: 'get',
    from: connection.jid,
    to: room,
  }).c('query', {
    xmlns: Strophe.NS.DISCO_INFO,
  });
  return new Promise((resolve, reject) => {
    connection.sendIQ(
      roomInfoIq,
      (stanza) => {
        console.debug('GetRoomInfo Stanza');
        console.debug(stanza);
        console.debug(xmlToJson(stanza));
        resolve(stanza);
        return true;
      },
      (stanza) => {
        console.debug('GetRoomInfo Stanza Error');
        console.debug(stanza);
        console.debug(xmlToJson(stanza));
        reject(stanza);
        return true;
      }
    );
  });
};

const modifyMemberAffiliation = (
  connection,
  room,
  bareJID,
  affiliation = 'owner'
) => {
  const iqBuilder = $iq({
    type: 'set',
    id: connection.getUniqueId(),
    from: connection.jid,
    to: getBareJID(room),
  })
    // @ts-ignore
    .c('query', { xmlns: Strophe.NS.MUC_ADMIN })
    .c('item', {
      affiliation,
      jid: bareJID,
    });
  connection.sendIQ(
    iqBuilder.tree(),
    (data) => console.log('done setting member affiliation => ', data),
    (err) => console.log('failed to set member affiliation => ', err)
  );
};

export const destroyRoom = (connection, roomBareJID, username, reason = '') => {
  const iqBuilder = $iq({
    type: 'set',
    id: connection.getUniqueId(),
    from: connection.jid,
    to: roomBareJID,
  })
    // @ts-ignore
    .c('query', { xmlns: Strophe.NS.MUC_OWNER })
    .c('destroy', {
      jid: roomBareJID,
    })
    .c('reason')
    .t(reason);
  return new Promise((resolve, reject) => {
    console.log('removing chat room => ', roomBareJID, username);
    try {
      connection.sendIQ(
        iqBuilder.tree(),
        () => resolve(true),
        (err) => reject(err)
      );
    } catch (err) {
      reject(err);
    }
  });
};
