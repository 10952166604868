import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton, Tooltip, Button, TextField } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StopIcon from '@material-ui/icons/Stop';
import SettingsIcon from '@material-ui/icons/Settings';
import PauseIcon from '@material-ui/icons/Pause';
import SendIcon from '@material-ui/icons/Send';
import MicIcon from '@material-ui/icons/Mic';
import { useDispatch } from 'react-redux';
import { sendVideoEmail } from '../redux/actions/member';
import Modal from './Modal/modal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minWidth: '640px',
    minHeight: 'calc(100vh - 350px)',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  videoWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  relative: {
    position: 'relative',
  },
  video: {
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  indicator: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    backgroundColor: 'red',
    width: '35px',
    height: '35px',
    zIndex: 100,
    fontSize: 0,
    border: 0,
    borderRadius: '35px',
    outline: 'none',
    animationName: `$pulse`,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0px 0px 5px 0px rgba(173,0,0,.3)',
    },
    '65%': {
      boxShadow: '0px 0px 5px 13px rgba(173,0,0,.3)',
    },
    '90%': {
      boxShadow: '0px 0px 5px 13px rgba(173,0,0,0)',
    },
  },
  alertWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& .MuiPaper-root': {
      width: '350px',
    },
    position: 'absolute',
    left: '50%',
    alignItems: 'center',
  },
  actionWrapper: {
    background: '#333333',
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'center',
    '& .MuiIconButton-root': {
      color: 'white',
      backgroundColor: 'black',
      marginLeft: theme.spacing(1),
    },
    '& .MuiIconButton-colorPrimary': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
  selectWrapper: {
    marginTop: theme.spacing(2),
    '& label': {
      fontWeight: 'bold',
    },
  },
  select: {
    display: 'block',
    width: '100%',
    height: '2.3em',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.common.white,
  },
}));

let mediaRecorderInstance, recordedBlobs;

/**
 *
 * @param {Object} props
 * @param {Boolean} props.show
 * @param {Number} props.receiver
 * @param {String} props.type
 * @param {Number} props.replied_id
 * @param {String} props.replySubject
 * @param {String} props.mediaType
 * @param {Function} props.setMediaType
 * @returns
 */
const VideoEmailEditor = ({
  show,
  receiver,
  type,
  replied_id,
  replySubject,
  mediaType,
  setMediaType,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [record, setRecord] = useState(false);
  const [recordingAvailable, setRecordingAvailable] = useState(false);
  const [playAvailable, setPlayAvailable] = useState(false);
  const [pause, setPause] = useState(false);
  const [deviceError, setDeviceError] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [subject, setSubject] = useState('');
  const [showSubjectEditor, setShowSubjectEditor] = useState(false);
  const [localDevices, setLocalDevices] = useState([]);
  /**
   * @typedef {Object} SelectedDeviceState
   * @property {Object} [audioSource]
   * @property {Object} [videoSource]
   */

  /**
   * @callback SelectedDeviceStateSetter
   * @param {SelectedDeviceState} state
   * @returns {void}
   */

  /**
   * @namespace {Object}
   * @property {SelectedDeviceState} 0
   * @property {SelectedDeviceStateSetter} 1
   */
  const [selectedDevices, setSelectedDevices] = useState({
    videoSource: undefined,
    audioSource: undefined,
  });
  const [showDeviceController, setShowDeviceController] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (show) {
      navigator.mediaDevices.addEventListener('devicechange', async (event) => {
        startStream();
        getDevices();
      });
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!show && localStream) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }, [show, localStream]);

  const handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0 && pause === false) {
      recordedBlobs.push(event.data);
      // setPlayAvailable(true);
    }
  };

  const startRecording = (stream) => {
    recordedBlobs = [];
    let options = { mimeType: 'video/webm;codecs=vp9,opus' };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      console.error(`${options.mimeType} is not supported`);
      options = { mimeType: 'video/webm;codecs=vp8,opus' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.error(`${options.mimeType} is not supported`);
        options = { mimeType: 'video/webm' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.error(`${options.mimeType} is not supported`);
          options = { mimeType: '' };
        }
      }
    }
    try {
      mediaRecorderInstance = new MediaRecorder(stream, options);
      if (videoRef && videoRef.current) {
        // videoRef.current.src = null;
        // videoRef.current.muted = true;
        // videoRef.current.srcObject = stream;
      }
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }

    mediaRecorderInstance.onstop = (event) => {
      console.log('Recorder stopped: ', event);
      console.log('Recorded Blobs: ', recordedBlobs);
    };
    mediaRecorderInstance.ondataavailable = handleDataAvailable;
    mediaRecorderInstance.start();
    if (mediaRecorderInstance.state !== 'inactive')
      mediaRecorderInstance.requestData();
  };

  const stopRecording = () => {
    if (!mediaRecorderInstance) return;
    if (mediaRecorderInstance.state !== 'inactive')
      mediaRecorderInstance.requestData();
    if (mediaRecorderInstance.state !== 'inactive')
      mediaRecorderInstance.stop();
    setPlayAvailable(true);
    setPause(false);
  };

  const startStream = (type = 'video') => {
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    let constraints = {
      audio: {
        deviceId:
          selectedDevices && selectedDevices.audioSource
            ? selectedDevices.audioSource
            : undefined,
      },
    };
    // start with normal device selected, add in resolution that might be requested
    // the request will be an object with potentially multiple key values, like
    let video = {
      deviceId:
        selectedDevices && selectedDevices.videoSource
          ? selectedDevices.videoSource
          : undefined,
    };

    if (type === 'video') {
      constraints = {
        audio:
          constraints.audio.deviceId === undefined ? true : constraints.audio,
        video: video.deviceId === undefined ? true : video,
      };
    } else {
      constraints = {
        audio:
          constraints.audio.deviceId === undefined ? true : constraints.audio,
        video: false,
      };
    }

    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setLocalStream(stream);
        return stream;
      })
      .catch((err) => {
        setDeviceError(true);
      });
  };

  useEffect(() => {
    setLocalStream(null);
    setDeviceError(false);

    return function cleanup() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (localStream) {
        localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStream && videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.srcObject = localStream;
      setRecordingAvailable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStream, videoRef.current]);

  const handleRecording = (type) => {
    setMediaType(type);
    setRecord((ps) => !ps);
  };

  const play = () => {
    const superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
    if (videoRef && videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.src = window.URL.createObjectURL(superBuffer);
      videoRef.current.muted = false;
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    if (pause && mediaRecorderInstance) mediaRecorderInstance.resume();
    else if (!pause && mediaRecorderInstance) mediaRecorderInstance.pause();
    setPause((ps) => !ps);
  };

  const send = () => {
    const formData = new FormData();
    const superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });

    formData.append('video_blob', superBuffer, 'test.webm');
    if (type === 'reply') formData.append('subject', replySubject);
    else formData.append('subject', subject);
    formData.append('type', type);
    formData.append('media_type', mediaType);
    formData.append(
      'replied_id',
      (replied_id && replied_id.toString()) || undefined
    );
    dispatch(sendVideoEmail(formData, receiver));
    setShowSubjectEditor(false);
  };

  const handleSubject = (event) => {
    event.persist();
    setSubject(event.target.value);
  };

  const ModalContent = () => (
    <div>
      <TextField
        label="Subject"
        color="primary"
        fullWidth={true}
        onChange={handleSubject}
      />
    </div>
  );

  const handleCancel = () => {
    setShowSubjectEditor(false);
  };

  const ModalAction = () => (
    <>
      <Button variant="contained" color="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={send}>
        Send
      </Button>
    </>
  );

  const handleDeviceChange = (type) => (event) => {
    if (type === 'video') {
      setSelectedDevices({
        videoSource: event.target.value,
        audioSource: undefined,
      });
    } else {
      setSelectedDevices({
        videoSource: undefined,
        audioSource: event.target.value,
      });
    }
  };

  useEffect(() => {
    stopRecording();
    if (record === true) {
      setPlayAvailable(false);
      startStream(mediaType).then((stream) => startRecording(stream));
    } else {
      startStream(mediaType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevices, record, mediaType]);

  const DeviceModalContent = () => (
    <>
      <div className={classes.selectWrapper}>
        <label htmlFor="audioSource">
          Local microphone (audio input source):{' '}
        </label>
        <select
          className={classes.select}
          id="audioSource"
          value={selectedDevices.audioSource}
          onChange={handleDeviceChange('audio')}
        >
          {localDevices
            .filter((device) => device.kind === 'audioinput')
            .map((device, index) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label ? device.label : `microphone ${index + 1}`}
              </option>
            ))}
        </select>
      </div>
      <div className={classes.selectWrapper}>
        <label htmlFor="videoSource">Local camera (Video source): </label>
        <select
          className={classes.select}
          id="videoSource"
          value={selectedDevices.videoSource}
          onChange={handleDeviceChange('video')}
        >
          {localDevices
            .filter((device) => device.kind === 'videoinput')
            .map((device, index) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label ? device.label : `camera ${index + 1}`}
              </option>
            ))}
        </select>
      </div>
    </>
  );

  const DeviceModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        setShowDeviceController(false);
      }}
    >
      Close
    </Button>
  );

  const getDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        setLocalDevices(devices);
      })
      .catch((error) => {
        console.log('error error', error);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.videoWrapper}>
        {record && <span className={classes.indicator} />}
        <div className={classes.relative}>
          <video
            id="faceRegisterVideo"
            className={classes.video}
            ref={videoRef}
            autoPlay
          ></video>
        </div>
      </div>
      <div className={classes.alertWrapper}>
        {deviceError && mediaType === 'video' && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>Please check if your webcam and mic works!</strong>
          </Alert>
        )}
        {deviceError && mediaType === 'audio' && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>Please check if your mic works!</strong>
          </Alert>
        )}
      </div>
      <div className={classes.actionWrapper}>
        <Tooltip title={record && mediaType === 'video' ? 'Stop' : 'Record'}>
          <span>
            <IconButton
              disabled={
                !recordingAvailable || (record && mediaType === 'audio')
              }
              onClick={() => {
                handleRecording('video');
              }}
            >
              {record && mediaType === 'video' ? (
                <StopIcon />
              ) : (
                <VideocamIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={record && mediaType === 'audio' ? 'Stop' : 'Audio Only Record'}
        >
          <span>
            <IconButton
              disabled={
                !recordingAvailable || (record && mediaType === 'video')
              }
              onClick={() => {
                handleRecording('audio');
              }}
            >
              {record && mediaType === 'audio' ? <StopIcon /> : <MicIcon />}
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={pause ? 'Resume' : 'Pause'}>
          <span>
            <IconButton disabled={!record} onClick={handlePause}>
              <PauseIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={playAvailable ? 'Preview' : 'Preview is not available'}>
          <span>
            <IconButton disabled={!playAvailable} onClick={play}>
              <VisibilityIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Setting">
          <span>
            <IconButton
              aria-label="setting"
              onClick={() => {
                setShowDeviceController(true);
              }}
            >
              <SettingsIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={playAvailable ? 'Send' : 'Sending is not available'}>
          <span>
            <IconButton
              color="primary"
              disabled={!playAvailable}
              onClick={() => {
                if (type === 'reply') send();
                else setShowSubjectEditor(true);
              }}
            >
              <SendIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <Modal
        open={showSubjectEditor}
        title="Set Mail Subject"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        disableBackdropClick={true}
        onClose={() => {
          setShowSubjectEditor(false);
        }}
      />
      <Modal
        open={showDeviceController}
        onClose={() => {
          setShowDeviceController(false);
        }}
        title="Settings"
        ModalContent={DeviceModalContent}
        ModalAction={DeviceModalAction}
        disableBackdropClick={true}
      />
    </div>
  );
};

VideoEmailEditor.propTypes = {
  show: PropTypes.bool,
  receiver: PropTypes.any,
};

export default VideoEmailEditor;
