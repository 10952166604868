import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../components/Modal/modal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { getFolderNames } from '../../../utils/fileshare';
import { includes } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AddFolder = (props) => {
  const { show, close, currentFolderId, fileTree, onNameConfirm } = props;
  const [isSyncing, setSyncing] = useState(false);
  const [folderName, setFolderName] = useState('New Folder');
  const [error, setError] = useState(null);

  const prevState = usePrevious({ isSyncing });

  const classes = useStyles();

  useEffect(() => {
    // @ts-ignore
    if (prevState && isSyncing === false && prevState.isSyncing === true) {
      close();
    }
  }, [isSyncing, close, prevState]);

  const handleAddClick = async () => {
    const siblingFolders = getFolderNames(fileTree, currentFolderId);

    if (folderName === '') {
      setError('Folder name is required');
    } else if (includes(siblingFolders, folderName)) {
      setError('Folder already exists. Please select another name');
    } else {
      debugger;
      setError(null);
      setSyncing(true);
      await onNameConfirm(folderName);
      setSyncing(false);
    }

    //   Backend call here
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleAddClick();
    }
  };

  const ModalContent = () => (
    <TextField
      fullWidth
      label="New folder name"
      variant="outlined"
      color="primary"
      value={folderName}
      autoFocus
      error={Boolean(error)}
      helperText={error ? error : ''}
      onChange={(e) => setFolderName(e.target.value)}
      onKeyPress={handleEnterPress}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setFolderName('')}>
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  const ModalAction = () => (
    <div className={classes.actionsWrapper}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.actionBtn}
        onClick={() => close()}
        disabled={isSyncing}
      >
        Cancel
      </Button>
      {isSyncing ? (
        <Button variant="contained" className={classes.actionBtn} disabled>
          <CircularProgress size={'1.5em'} className={classes.buttonProgress} />
          Adding...
        </Button>
      ) : (
        <Button
          startIcon={<CreateNewFolderIcon />}
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          disableRipple
          onClick={handleAddClick}
        >
          Add
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      open={show}
      onClose={close}
      title="Add folder"
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="add-folder-modal"
    ></Modal>
  );
};

export default AddFolder;
