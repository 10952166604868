import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { debounce } from 'lodash';
import Message from './Message';
import MessageDivider from './MessageDivider';
import ConversationEmpty from './ConversationEmpty';
import LinearProgressWithLabel from '../../../../components/LinearProgressWithLabel';
import {
  groupMessagesByDate,
  getMessagesDividerDate,
} from '../../../../utils/strophe/chat';
import { displayableFullName } from '../../../../utils/contact';
import { STROPHE_CHUNK_SIZE } from '../../../../utils/file';

// import MessageSkeleton from './MessageSkeleton';
// import usePrevious from '../../../../hooks/usePrevious';
import {
  loadContactMessages,
  loadGroupMessages,
} from '../../../../redux/actions/chat';
function ChatMessages(props) {
  const {
    isGroupChat = false,
    textSecondary,
    memberInfo,
    chatConversations,
    groupConversations,
    selectedChatID,
    room,
    contacts,
    messagesEnfRef,
  } = props;
  const { member_id, first_name, last_name } = memberInfo;
  const memberAvatarUrl = `/member/${member_id}/avatar`;

  // const [messagesGroupedByDate, setMessagesGroupedByDate] = useState({});
  // const prevSelectedChatConversationId = usePrevious(selectedChatID);
  // const prevSelectedGroupConversationId = usePrevious(room);

  const dispatch = useDispatch();

  const debouncedLoadMessages = useCallback(
    debounce((nextValue, loadMore) => {
      if (!isGroupChat) dispatch(loadContactMessages(nextValue, loadMore), 500);
      else dispatch(loadGroupMessages(nextValue, loadMore), 500);
    }),
    [isGroupChat]
  );

  const handleScroll = useCallback(
    (e) => {
      let element = e.target;
      if (
        Math.abs(
          element.scrollHeight -
            Math.abs(element.scrollTop) -
            element.clientHeight
        ) <= 1
      ) {
        debouncedLoadMessages(isGroupChat ? room : selectedChatID, true);
      }
    },
    [isGroupChat, selectedChatID, room, debouncedLoadMessages]
  );

  let selectedConversation = null,
    contactData = {};
  if (isGroupChat) {
    selectedConversation =
      (room &&
        groupConversations &&
        groupConversations.filter((c) => c.chatID === room)) ||
      null;
  } else {
    selectedConversation =
      selectedChatID &&
      chatConversations &&
      chatConversations.filter((c) => c.chatID === selectedChatID);

    contactData = contacts.find(
      (c) => c.contact_member_id.toString() === selectedChatID
    );
  }

  selectedConversation = selectedConversation && selectedConversation[0];
  const messages =
    (selectedConversation && selectedConversation?.messages) || [];

  // const prevMessages = usePrevious(messages);

  let messagesGroupedByDate = groupMessagesByDate(messages);

  // useEffect(() => {
  //   if (
  //     // messages?.length > 0 ||
  //     // selectedChatID !== prevSelectedChatConversationId
  //     // (room !== prevSelectedGroupConversationId)
  //     JSON.stringify(messages) !== JSON.stringify(prevMessages)
  //   ) {
  //     setMessagesGroupedByDate(groupMessagesByDate(messages));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   // selectedChatID,
  //   // prevSelectedChatConversationId,
  //   // prevSelectedGroupConversationId,
  //   messages,
  //   prevMessages,
  // ]);

  useEffect(() => {
    if (selectedChatID) {
      // load encrypted messages
      dispatch(loadContactMessages(selectedChatID));
    }
  }, [dispatch, selectedChatID]);

  let messageItems = null;
  // if (loadingMessages) {
  //   messageItems = [...Array(10).keys()].map((num) => (
  //     <MessageSkeleton key={num} num={num} />
  //   ));
  // } else

  // find first message in which one or more file uploading/downloading
  const msg = messages?.find((msg) => msg.filesLoading);
  // if there more then one file then consider first one (from loading files)
  const file = msg?.messageFiles?.find((f) => f.loading);
  let fileProgressValue = -1;
  if (file && file.seq) {
    const sequenceEnd = Math.ceil(file.size / STROPHE_CHUNK_SIZE);
    const currentSequence = file.seq;
    fileProgressValue = Math.ceil((currentSequence / sequenceEnd) * 100);
  }

  if (isGroupChat && selectedConversation?.isDestroyed) {
    messageItems = (
      <ConversationEmpty
        isGroupChat={isGroupChat}
        textPrimary={'Group Deleted'}
        textSecondary={selectedConversation?.destroyMessage}
      />
    );
  } else if (
    messagesGroupedByDate &&
    Object.keys(messagesGroupedByDate).length > 0
  ) {
    messageItems = Object.keys(messagesGroupedByDate).map((date) => {
      return (
        <React.Fragment key={date}>
          {messagesGroupedByDate[date].map((message) => {
            const isMyMessage = message.isMyMessage;
            let firstName = '',
              lastName = '';
            if (isGroupChat) {
              let roster = selectedConversation?.groupRosters?.filter(
                (r) => r.rosterID === message.senderID
              )?.[0];
              if (roster) {
                firstName = roster.firstName || '';
                lastName = roster.lastName || '';
              }
            } else {
              firstName = isMyMessage
                ? first_name
                : contactData?.first_name || '';
              lastName = isMyMessage ? last_name : contactData?.last_name || '';
            }

            return (
              <Message
                id={message.id}
                key={message.id}
                isMyMessage={isMyMessage}
                archived={message.archived}
                member_id={message.senderID}
                firstName={firstName}
                lastName={lastName}
                stamp={message.stamp}
                messageText={message.messageText}
                messageType={message.messageType}
                messageFiles={message.messageFiles}
              />
            );
          })}
          <ListItem key={date}>
            <MessageDivider key={date}>
              {getMessagesDividerDate(date)}
            </MessageDivider>
          </ListItem>
        </React.Fragment>
      );
    });
  } else {
    const textPrimary =
        !selectedChatID || !room ? (
          <span className="welcome-share-ecp-message">
            Welcome to SHARE<small>ECP</small>&#8482;!&nbsp;
            {displayableFullName({ first_name, last_name })}
          </span>
        ) : null,
      newTextSecondary = selectedChatID
        ? textSecondary
        : isGroupChat
        ? `No message found in ${
            selectedConversation?.chatID !== undefined
              ? selectedConversation?.chatID
              : ''
          } group`
        : 'Please select any contact to start chat.';

    messageItems = (
      <ConversationEmpty
        isGroupChat={isGroupChat}
        textPrimary={textPrimary}
        textSecondary={newTextSecondary}
        contactFirstName={contactData?.first_name || null}
        contactLastName={contactData?.last_name || null}
        contactAvatarURL={contactData?.amera_avatar_url || null}
        contactMemberID={contactData?.contact_member_id || null}
        memberFirstName={first_name}
        memberLastName={last_name}
        memberAvatarURL={memberAvatarUrl || null}
        memberID={member_id}
      />
    );
  }

  return (
    <>
      {fileProgressValue >= 0 && fileProgressValue < 100 && (
        <div className="file-share-progress">
          <LinearProgressWithLabel progress={fileProgressValue} />
        </div>
      )}
      <List
        className="chat-messages"
        onScroll={handleScroll}
        style={{ overflowY: /* loadingMessages ? 'hidden' : */ 'auto' }}
      >
        <div ref={messagesEnfRef} />
        {messageItems}
      </List>
    </>
  );
}

ChatMessages.defaultProps = {
  isGroupChat: false,
  textSecondary: `This is the very beginning of your direct message history. 
                        Only the two of you are in this conversation, 
                            and no one else can join it.`,
};

const mapStateToProps = (state) => ({
  memberInfo: state.member.memberInfo,
  chatConversations: state.chat.chatConversations,
  groupConversations: state.chat.groupConversations,
  selectedChatID: state.chat.selectedChatID,
  contacts: state.contact.contacts,
});

export default connect(mapStateToProps)(ChatMessages);
