import React, { useEffect, useState, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import BorderLinearProgress from '../../components/BorderLinearProgressBar';
import MaterialAvatar from '../../components/MaterialAvatar';
import UpdateContactRoleModal from '../../components/Modal/UpdateContactRoleModal';
import {
  setCallPartner,
  setAudioOnly,
  setCallPartnerMemberId,
} from '../../redux/actions/one2onevcall';
import {
  displayableFullName,
  getContactDevice,
  getContactLocation,
} from '../../utils/contact';
import { establishContactVideoConference } from '../../utils/general';
import { openDrawer } from '../../redux/actions/drawer';
import { getCompanyDetail } from '../../redux/actions/company';
// import ContactProfile from '../Profile/Profiles/ContactProfile';
// import SVG from '../../components/Icons/SVG';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import SecurityDetailModal from '../../components/Modal/SecurityDetailModal';
import AmeraPagination from '../../components/AmeraPagination';
import VideoEmailEditorModal from '../../components/Modal/VideoEmailEditorModal';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';
import { ICON_BUTTON_TYPES } from '../../utils/general';
import AmeraIconButton from '../../components/AmeraIconButton';
import LabeledValue from '../../components/LabeledValue';
import { getContactOnlineStatus } from '../../utils/strophe/user';
import { getMemberInfo } from '../../redux/actions/contact';
import AddressLine from '../../components/AddressLine';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: '1px solid #D4E2F0',
    boxShadow: `0px 2px 1px -1px #D4E2F0, 0px 1px 1px 0px #D4E2F0, 0px 1px 3px 0px #D4E2F0`,
  },
  title: {
    fontWeight: 800,
    fontSize: 18,
  },
  leftTitle: {
    maxWidth: 290,
  },
  rightTitle: {
    maxWidth: 130,
  },
  securityPercentage: {
    marginTop: -19,
    marginRight: 27,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 700,
    position: 'relative',
    color: 'black',
  },
  securityLabel: {
    fontSize: 12,
    fontWeight: 700,
    paddingLeft: 13,
    marginTop: 5,
    color: 'black',
  },
}));

const ContactStatus = (props) => {
  let { status } = props;
  if (status === 'online') {
    return (
      <Tooltip title="Online" arrow>
        <IconButton aria-label="Online" className="online-icon">
          <DesktopWindowsIcon />
        </IconButton>
      </Tooltip>
    );
  } else if (status === 'offline') {
    return (
      <Tooltip title="Offline" arrow>
        <IconButton aria-label="Offline" className="offline-icon">
          <DesktopAccessDisabledIcon />
        </IconButton>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};
ContactStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
ContactStatus.defaultPage = {
  status: '',
};

const CardContainer = (props) => {
  const {
    member,
    totalCount,
    contactData,
    loadSortedContacts,
    pageSize,
    pageNumber,
    handleChangePage,
    handleChangeRowsPerPage,
    loadContactCompanies,
    loadContactCountries,
    loadContactRoles,
    openDetailsFor,
    onSchedule,
  } = props;
  const [isShowSecurityModal, setShowSecurityModal] = useState(false);
  const [selectedContacts, setSlectedContacts] = useState([]);
  const [contactDetailData, setContactDetailData] = useState({});
  const [showVideoEmailEditor, setShowVideoEmailEditor] = useState(false);
  // const [showDetailModal, setShowDetailModal] = useState(false);
  const [showContactRoleModal, setShowContactRoleModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const rosterItems = useSelector((s) => s?.chat?.rosterItems);

  // useEffect(() => {
  //   setNoOfPages(Math.ceil(contactData.length / itemsPerPage));
  // }, [itemsPerPage, contactData]);

  const showContactDetail = useCallback(
    async (contactMemberId) => {
      console.log("let's see", console.log(new Date()));
      await dispatch(getMemberInfo(contactMemberId));
      console.log("let's see", console.log(new Date()));
      dispatch(
        openDrawer(DRAWER_CONTENT_TYPE.contactProfile, { contactMemberId })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!!openDetailsFor) {
      showContactDetail(openDetailsFor);
    }
  }, [openDetailsFor, showContactDetail]);

  const showContactSecurity = (contact) => {
    setContactDetailData(contact);
    setShowSecurityModal(true);
  };

  const showContactRoles = (contact) => {
    setContactDetailData(contact);
    setShowContactRoleModal(true);
  };

  const closeAndReloadContacts = () => {
    loadSortedContacts();
    loadContactCompanies();
    loadContactCountries();
    loadContactRoles();
    setShowContactRoleModal(false);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = contactData.map((n) => n.id);
  //     setSlectedContacts(newSelecteds);
  //     return;
  //   }
  //   setSlectedContacts([]);
  // };

  const handleSelect = (event, contact_member_id) => {
    let item = contactData.find(
      (el) => el.contact_member_id === contact_member_id
    );
    let findItem = selectedContacts.findIndex(
      (el) => el.contact_member_id === contact_member_id
    );
    if (findItem === -1) {
      setSlectedContacts([...selectedContacts, item]);
    } else {
      let newSelected = [...selectedContacts];
      newSelected.splice(findItem, 1);
      setSlectedContacts([...newSelected]);
    }
  };

  const handleCloseSecurityModal = () => {
    loadSortedContacts();
    setShowSecurityModal(false);
  };

  // const goOne2OneVideoCall = (partner, notificationData, audioOnly = false) => {
  //   props.setCallPartner(partner);
  //   if (audioOnly) dispatch(setAudioOnly(true));
  //   else dispatch(setAudioOnly(false));
  //   dispatch(setCallPartnerMemberId(partner.member_id, 'call'));
  //   establishContactVideoCall(
  //     notificationData.member,
  //     notificationData.contact,
  //     audioOnly
  //   );
  //   history.push(
  //     `${process.env.PUBLIC_URL}/contacts/call/video/${partner.member_id}`
  //   );
  // };

  const openContactVideoConferenceNotify = async (
    partner,
    notificationData
  ) => {
    props.setCallPartner(partner);
    dispatch(setAudioOnly(false));
    dispatch(setCallPartnerMemberId(partner.member_id, 'call'));
    const conferenceUrl = await establishContactVideoConference(
      notificationData.member,
      notificationData.contact
    );
    history.push(`${conferenceUrl}`);
  };

  const openVideoEmailEditModal = (contact) => {
    setShowVideoEmailEditor(true);
    setContactDetailData(contact);
  };

  const handleCompanyClick = async (companyId) => {
    if (!!companyId) {
      dispatch(getCompanyDetail(companyId));
      dispatch(openDrawer(DRAWER_CONTENT_TYPE.companyProfile, { companyId }));
    }
  };

  const openContactChat = async (contact) => {
    history.push(
      `${process.env.PUBLIC_URL}/contacts/chat?chatID=${contact.contact_member_id}`
    );
  };

  const openContactAudioCall = () => {
    return;
  };

  return (
    <>
      <SecurityDetailModal
        type="contact"
        open={isShowSecurityModal}
        onClose={() => handleCloseSecurityModal()}
        contact_id={contactDetailData.contact_member_id}
      />
      {/* <PageSettingsModal
        type="contacts"
        view={view}
        loading={loading}
        open={showContactsSetting}
        orderBy={orderBy}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onClose={() => setShowContactsSetting(false)}
        setOrderBy={setOrderBy}
        onClick={loadSortedContacts}
      /> */}
      <UpdateContactRoleModal
        contact={contactDetailData}
        open={showContactRoleModal}
        onClose={() => closeAndReloadContacts()}
      />

      <Collapse in={selectedContacts.length > 0}>
        <EnhancedTableToolbar
          title=""
          selectedContacts={selectedContacts}
          handleCreateGroup={props.goToCreateGroup}
          handleScheduleEvent={onSchedule}
          handleDeleteContacts={(selectedContacts) => {
            props.deleteContactsFunc(selectedContacts);
            setSlectedContacts([]);
          }}
          setCompose={props.setCompose}
        />
      </Collapse>
      <Grid container className="contact-container">
        {contactData.map((contact) => {
          let company_location = <>Not Available</>;
          const member_location_work = getContactLocation(contact, 'work');

          if (
            contact.company_membership &&
            contact.company_membership.locality
          ) {
            company_location = (
              <AddressLine data={contact.company_membership} simple={true} />
            );
          } else if (member_location_work && member_location_work.length > 0) {
            company_location = (
              <AddressLine data={member_location_work[0]} simple={true} />
            );
          }

          return (
            <Grid
              item
              md={6}
              sm={12}
              lg={4}
              key={contact.id}
              className="contact-card"
            >
              <Card className={classes.cardContainer}>
                <div className="custom-card_header">
                  <MaterialAvatar
                    classes="contact"
                    member_id={contact.contact_member_id}
                    src={contact.amera_avatar_url}
                    onClick={() => showContactDetail(contact.contact_member_id)}
                    firstName={contact.first_name}
                    lastName={contact.last_name}
                  />
                  <div className="select-checkbox">
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      checked={selectedContacts.some(
                        (selectedContact) =>
                          selectedContact.contact_member_id ===
                          contact.contact_member_id
                      )}
                      onClick={(event) =>
                        handleSelect(event, contact.contact_member_id)
                      }
                    />
                  </div>
                  <div className="primary-title">
                    <div className={classes.leftTitle}>
                      <div className={classes.title}>
                        {displayableFullName(contact)}
                      </div>
                      <div
                        className={classNames('primary-header', {
                          clickable: !!contact.company_id,
                        })}
                        onClick={() => handleCompanyClick(contact.company_id)}
                      >
                        {contact.company_id
                          ? contact.member_company_name
                          : contact.company}
                      </div>
                      <div className="header-role">{contact.title}</div>
                      <div className="header-address">{company_location}</div>
                    </div>
                    <div className={classes.rightTitle}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          contact?.security_exchange_option
                            ? contact.security_exchange_option
                            : 0
                        }
                        onClick={() => showContactSecurity(contact)}
                      />
                      <p className={classes.securityPercentage}>
                        {contact?.security_exchange_option
                          ? contact.security_exchange_option
                          : 0}
                        %
                      </p>
                      <p
                        className={classes.securityLabel}
                        onClick={() => showContactSecurity(contact)}
                      >
                        Security Level
                      </p>
                      <div className="contact-actions">
                        <div className="text-container contact-role">
                          <div className="content_list">
                            <p className="content_key">Relationship</p>
                            <Link
                              component="button"
                              variant="body2"
                              className="content_value"
                              onClick={() => {
                                showContactRoles(contact);
                              }}
                            >
                              {contact.role || 'Not Available'}
                            </Link>
                          </div>
                        </div>
                        <div className="online-status-button">
                          {
                            <ContactStatus
                              status={getContactOnlineStatus(
                                contact,
                                rosterItems
                              )}
                              contact={contact}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CardActions disableSpacing className="contact-card-action">
                  <AmeraIconButton
                    type={ICON_BUTTON_TYPES.chat}
                    onClick={() => openContactChat(contact)}
                  />
                  <AmeraIconButton type={ICON_BUTTON_TYPES.fileshare} />
                  <AmeraIconButton
                    type={ICON_BUTTON_TYPES.email}
                    onClick={() => {
                      props.setCompose({
                        open: true,
                        initialState: {
                          receivers: [
                            {
                              email: contact.email,
                              member: contact.member_name,
                              first_name: contact.first_name,
                              last_name: contact.last_name,
                              contact_member_id: contact.contact_member_id,
                            },
                          ],
                        },
                      });
                    }}
                  />
                  <AmeraIconButton
                    type={ICON_BUTTON_TYPES.conference}
                    onClick={() => {
                      openContactVideoConferenceNotify(
                        {
                          email: contact.email,
                          member_id: contact.contact_member_id,
                          first_name: contact.first_name,
                          last_name: contact.last_name,
                        },
                        { member, contact }
                      );
                    }}
                  />
                  <AmeraIconButton
                    type={ICON_BUTTON_TYPES.call}
                    onClick={openContactAudioCall}
                  />
                  <AmeraIconButton
                    type={ICON_BUTTON_TYPES.schedule}
                    onClick={() => onSchedule([contact])}
                  />
                  <AmeraIconButton
                    type={ICON_BUTTON_TYPES.videoMail}
                    onClick={() => {
                      openVideoEmailEditModal(contact);
                    }}
                  />
                </CardActions>
                <CardContent className="contact-card_content">
                  <LabeledValue
                    label="Cell phone"
                    value={
                      getContactDevice(contact, 'Cell phone') || 'Not Available'
                    }
                  />
                  <LabeledValue
                    label="Office phone"
                    value={
                      getContactDevice(contact, 'Office phone') ||
                      'Not Available'
                    }
                  />

                  {contact.email && contact.personal_email ? (
                    <FormControl className="contact-email-list">
                      <Select
                        value={contact.email}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value={contact.email}>
                          {contact.email}
                        </MenuItem>
                        <MenuItem value={contact.personal_email}>
                          {contact.personal_email}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <LabeledValue label="Office email" value={contact.email} />
                  )}
                </CardContent>
                {/* <CardActions
                    disableSpacing
                    className="contact-card-action-button"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showContactDetail(contact)}
                    >
                      view profile
                    </Button>
                  </CardActions> */}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <AmeraPagination
        rowsPerPage={pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalCount}
        page={pageNumber}
        handleChangePage={handleChangePage}
      />

      <VideoEmailEditorModal
        open={showVideoEmailEditor}
        receiver={contactDetailData.contact_member_id}
        type="contact"
        name={`${contactDetailData.first_name} ${contactDetailData.last_name}`}
        onClose={() => {
          setShowVideoEmailEditor(false);
        }}
      />
    </>
  );
};

CardContainer.propTypes = {
  member: PropTypes.object,
  contactData: PropTypes.arrayOf(PropTypes.object),
  filterValues: PropTypes.arrayOf(PropTypes.object),
  setCallPartner: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

CardContainer.defaultProps = {
  member: {},
  contactData: [],
  filterValues: [],
  pageNumber: 0,
  pageSize: 25,
};

const mapStateToProps = (state) => ({
  member: state.member.member,
  loading: state.contact.loading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setCallPartner }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
