import { Strophe, $iq, $pres } from 'strophe.js';
// import { xmlToJson } from '../chat';

export const CHAT_STATUS = {
  chat: 'chat',
  away: 'away',
};

export const setUserStatus = (connection, status) => {
  console.debug(`SetUserStatus: ${status}`);
  const presStatus = $pres({ status: status }).c('show').t(status);
  return new Promise((resolve, reject) => {
    connection.sendPresence(
      presStatus,
      (stanza) => {
        console.debug(`SetUserStatus presence sent`, connection.jid);
        console.debug(stanza);
        resolve(stanza);
        return true;
      },
      (stanza) => {
        console.debug(`SetUserStatus presence error`);
        console.debug(stanza);
        reject(stanza);
        return true;
      }
    );
  });
};

export const getUserInfoVCard = (connection, jid) => {
  let iqConf = {
    type: 'get',
    from: connection.jid,
  };
  if (jid && jid !== connection.jid) {
    iqConf['to'] = getBareJID(jid);
  }
  const iq = $iq(iqConf).c('vCard', { xmlns: 'vcard-temp' });
  return new Promise((resolve, reject) => {
    console.debug('GetUserInfoVcard promise');
    connection.sendIQ(
      iq,
      (stanza) => {
        // debugger;
        console.debug('GetUserInfoVcard Stanza response');
        console.debug(stanza);
        resolve(stanza);
        return true;
      },
      (stanza) => {
        // debugger;
        console.debug('GetUserInfoVcard Stanza Error');
        console.debug(stanza);
        reject(stanza);
        return true;
      }
    );
  });
};

export const setUserInfoVCard = (
  connection,
  name,
  firstName,
  lastName,
  amera_id,
  avatar_url
) => {
  return new Promise((resolve, reject) => {
    const iq = $iq({
      type: 'set',
      // to: connection.jid,
    })
      .c('vCard', { xmlns: 'vcard-temp' })
      .c('VERSION', '3.0')
      .up()
      .c('FN', name)
      .up()
      .c('NICKNAME', name)
      .up()
      .c('FIRSTNAME', firstName)
      .up()
      .c('LASTNAME', lastName)
      .up()
      .c('AMERAID', amera_id)
      .up()
      .c('PHOTO')
      .c('EXTVAL', avatar_url);
    console.debug('SetUserInfoVCard Promise');
    console.debug(iq);
    connection.sendIQ(
      iq,
      (stanza) => {
        console.debug('SetUserInfoVCard Response');
        console.debug(stanza);
        resolve(stanza);
        return true;
      },
      (stanza) => {
        console.debug('SetUserInfoVCard Error');
        console.debug(stanza);
        reject(stanza);
        return true;
      },
      2
    );
  });
};
// const configureRosterManagement = () => {
//   /*
//   <iq from='icq.example.com' to='juliet@example.com' type='set' id='roster_1'>
//     <query xmlns='urn:xmpp:tmp:roster-management:0' reason='Manage contacts in the ICQ contact list' type='request'/>
//   </iq>
//   */
//   // This asks the server to have amera manage the roster/contact list for
//   // the logged in user
//   const iqRoster = $iq({
//     type: 'set',
//     id: `roster_${member.member_id}`,
//     to: connection.jid,
//     from: config.hosts.domain
//   })
//   iqRoster.c('query', {
//     xmlns: 'urn:xmpp:tmp:roster-management:0',
//     type: 'request',
//     reason: 'management'
//   })
//   connection.sendIQ(iqRoster);
// }

// const getIqPresence = () => {
//   debugger;
//   const presenceUsers = [];
//   const iq = $iq({type: 'get'}).c('query', {xmlns: 'jabber:iq:roster'});
//   connection.sendIQ(iq, (iq) => {
//     debugger;
//     iq.querySelectorAll('item').forEach(async (item) => {
//       const jid = item.getAttribute('jid'); // The jabber_id of your contact
//       // You can probably put them in a unordered list and and use their jids as ids.
//       const user = await getIq(jid);
//       presenceUsers.push(user);
//     });
//     // connection.addHandler(onPresence, null, 'presence');
//     connection.send($pres());
//   })
// }

export const getUserIdFromJID = (JID) => {
  return Strophe.getNodeFromJid(JID);
};

export const getResourceFromJID = (JID) => {
  return Strophe.getResourceFromJid(JID);
};

export const getBareJID = (JID) => {
  return Strophe.getBareJidFromJid(JID);
};

export const getDomainFromJID = (JID) => {
  return Strophe.getDomainFromJid(JID);
};

export const createBareJIDFromUserID = (connection, ID) => {
  return `${ID}@${getDomainFromJID(connection.jid)}`;
};

export const getContactOnlineStatus = (contact = {}, rosterItems = []) => {
  const contactMemberId = contact.contact_member_id;
  rosterItems = rosterItems || [];
  console.debug('Roster & Contact MemberID');
  console.debug(contact, contactMemberId, rosterItems);
  if (contactMemberId && rosterItems.length > 0) {
    const rosterContact = rosterItems.find(
      (ri) => getUserIdFromJID(ri.jid) === contact.contact_member_id.toString()
    );
    console.debug(rosterContact);
    console.debug('Roster & Contact MemberID Find');
    if (
      !rosterContact ||
      !rosterContact.resources ||
      !rosterContact.resources.browser
    ) {
      return 'offline';
    }
    console.debug('Roster & Contact MemberID NOT OFFLINE');
    return rosterContact.resources.browser.show === CHAT_STATUS.chat
      ? 'online'
      : 'offline';
  }
  return 'offline';
};
