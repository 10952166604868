import React from 'react';

const LogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    // height="height"
    height="72.875"
    viewBox="0 0 70 72.875"
  >
    <defs>
      <filter
        id="_1"
        x="0"
        y="5.875"
        width="70"
        height="67"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="1" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.5" result="blur" />
        <feFlood floodColor="#fff" floodOpacity="0.502" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Logo" transform="translate(1.469)">
      <g id="logo-2" data-name="logo" transform="translate(-26.469 -22.125)">
        <g transform="matrix(1, 0, 0, 1, 25, 22.12)" filter="url(#_1)">
          <rect
            id="_1-2"
            data-name="1"
            width="66"
            height="63"
            rx="10"
            transform="translate(2 6.88)"
            fill="none"
            stroke="#649f30"
            strokeWidth="1"
          />
        </g>
      </g>
      <g
        id="Group_2384"
        data-name="Group 2384"
        transform="translate(-92.469 -145.125)"
      >
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(-1965.29 -1149.634)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M2122.414,1345.03v4.609l-1,1.348h-30.161v-14.962h-14.483v1.866l-6.8-3.972,6.8-3.974v1.867h30.878v1.907l6.8-3.974-6.8-3.973v1.866h-16.395v-13.6h-12.588l-.925-2.328a4.46,4.46,0,0,0-3.993-2.729h-12.322a3.692,3.692,0,0,0-3.665,3.709V1349.3a3.691,3.691,0,0,0,3.665,3.709h59.657a3.69,3.69,0,0,0,3.665-3.709v-35.257h-2.332Z"
            transform="translate(0 -8.375)"
            fill="#94c03d"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M2121.082,1299.792h-42.417l-.925-2.318a4.46,4.46,0,0,0-3.993-2.716h-12.322a3.683,3.683,0,0,0-3.665,3.692v5.852a3.683,3.683,0,0,1,3.665-3.691h12.322a4.459,4.459,0,0,1,3.993,2.715l.925,2.317h46.082v-2.16A3.683,3.683,0,0,0,2121.082,1299.792Z"
            transform="translate(0)"
            fill="#649f30"
          />
        </g>
        <g
          id="Group_6"
          data-name="Group 6"
          transform="translate(-1939.645 -1242.345)"
        >
          <g
            id="Group_3"
            data-name="Group 3"
            transform="translate(2040.645 1441.22)"
          >
            <g id="Group_2" data-name="Group 2" transform="translate(0)">
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2047.29,1454.669h-3.905l-.745,1.8h-1.995l3.75-8.412h1.923l3.761,8.412h-2.043Zm-.613-1.478-1.334-3.22-1.334,3.22Z"
                transform="translate(-2040.645 -1448.06)"
                fill="#649f30"
              />
              <path
                id="Path_4"
                data-name="Path 4"
                d="M2096.219,1456.472l-.012-5.047-2.476,4.158h-.877l-2.464-4.05v4.939h-1.826v-8.412h1.61l3.148,5.227,3.1-5.227h1.6l.024,8.412Z"
                transform="translate(-2078.254 -1448.06)"
                fill="#649f30"
              />
              <path
                id="Path_5"
                data-name="Path 5"
                d="M2148.416,1454.91v1.562H2141.9v-8.412h6.357v1.562h-4.422v1.826h3.906v1.514h-3.906v1.947Z"
                transform="translate(-2120.115 -1448.06)"
                fill="#649f30"
              />
              <path
                id="Path_6"
                data-name="Path 6"
                d="M2184.738,1456.472l-1.622-2.343h-1.791v2.343h-1.947v-8.412h3.641c2.247,0,3.653,1.166,3.653,3.052a2.734,2.734,0,0,1-1.73,2.656l1.887,2.7Zm-1.826-6.826h-1.586v2.932h1.586c1.189,0,1.79-.553,1.79-1.466S2184.1,1449.646,2182.912,1449.646Z"
                transform="translate(-2149.527 -1448.06)"
                fill="#649f30"
              />
              <path
                id="Path_7"
                data-name="Path 7"
                d="M2221.936,1454.669h-3.906l-.745,1.8h-1.995l3.75-8.412h1.922l3.761,8.412h-2.043Zm-.613-1.478-1.333-3.22-1.334,3.22Z"
                transform="translate(-2177.711 -1448.06)"
                fill="#649f30"
              />
            </g>
          </g>
          {/* <g
            id="Group_4"
            data-name="Group 4"
            transform="translate(2073.25 1451.075)"
          >
            <path
              id="Path_8"
              data-name="Path 8"
              d="M2192.173,1496.149l.2-.434a1.466,1.466,0,0,0,.822.26c.342,0,.48-.114.48-.267,0-.466-1.448-.146-1.448-1.071,0-.424.342-.776,1.05-.776a1.671,1.671,0,0,1,.865.22l-.178.438a1.41,1.41,0,0,0-.691-.2c-.342,0-.473.128-.473.285,0,.459,1.449.143,1.449,1.057,0,.416-.345.772-1.057.772A1.777,1.777,0,0,1,2192.173,1496.149Z"
              transform="translate(-2192.173 -1493.86)"
              fill="#737677"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M2207.573,1494.058v2.491H2207v-1.021h-1.132v1.021h-.576v-2.491h.576v.982H2207v-.982Z"
              transform="translate(-2202.466 -1494.015)"
              fill="#737677"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M2220.752,1496.016H2219.6l-.221.533h-.591l1.11-2.491h.57l1.114,2.491h-.6Zm-.181-.438-.4-.954-.4.954Z"
              transform="translate(-2213.058 -1494.015)"
              fill="#737677"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M2236.216,1496.549l-.481-.694h-.53v.694h-.577v-2.491h1.078c.666,0,1.082.345,1.082.9a.81.81,0,0,1-.512.787l.559.8Zm-.541-2.021h-.469v.868h.469c.352,0,.531-.164.531-.434S2236.027,1494.528,2235.675,1494.528Z"
              transform="translate(-2225.494 -1494.015)"
              fill="#737677"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M2250.367,1496.087v.462h-1.929v-2.491h1.883v.463h-1.31v.541h1.157v.448h-1.157v.577Z"
              transform="translate(-2236.331 -1494.015)"
              fill="#737677"
            />
          </g> */}
          <g
            id="Group_5"
            data-name="Group 5"
            transform="translate(2087.084 1439.345)"
          >
            <path
              id="Path_13"
              data-name="Path 13"
              d="M2257.044,1439.683h-.574v-.338h1.564v.338h-.574v1.457h-.416Z"
              transform="translate(-2256.47 -1439.345)"
              fill="#737677"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M2266.414,1441.14l0-1.077-.528.887h-.187l-.525-.864v1.054h-.39v-1.795h.344l.672,1.115.662-1.115h.341l.005,1.795Z"
              transform="translate(-2262.992 -1439.345)"
              fill="#737677"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoIcon;
