import parsePhoneNumber from 'libphonenumber-js';
import { capitalize } from 'lodash';
import { parseISO, format, isValid, formatDistanceStrict } from 'date-fns';
import { defaultDateTimeZone } from './calendar';

export const DEVICE_TYPES = {
  email: 'email',
  cell: 'cell',
  landline: 'landline',
  TDD: 'TDD',
  other: 'other',
};

export const DEVICE_METHOD = {
  text: 'text',
  html: 'html',
  sms: 'sms',
  voice: 'voice',
  other: 'other',
};

export const formatPhoneNumber = (countryPhonecode, number) => {
  if (!number || String.prototype.trim.call(number) === '') {
    return '';
  }
  const nationalPhoneNumber = parsePhoneNumber(
    `+${countryPhonecode}${number}`
  ).formatNational();
  return `+${countryPhonecode} ${nationalPhoneNumber}`;
};

/**
 *
 * @typedef CountryCodeInfo
 * @type {Object}
 * @property {String} alpha2
 * @property {String} alpha3
 * @property {RegExp} cell_regexp
 * @property {Number} currency_code_id
 * @property {Number} display_order
 * @property {String} domain
 * @property {Number} id
 * @property {Boolean} is_enabled
 * @property {String} name
 * @property {Number} phone
 */

/**
 *
 * @typedef ContactDeviceInfo
 * @type {Object}
 * @property {Date} create_date
 * @property {String} description
 * @property {String} device
 * @property {String} device_confirm_date
 * @property {Number} device_country
 * @property {String} device_type
 * @property {Number} display_order
 * @property {Boolean} enabled
 * @property {Number} id
 * @property {Number} member_id
 * @property {Number} member_location_id
 * @property {String} method_type
 * @property {Boolean} outgoing_caller
 * @property {Boolean} outgoing_caller_verified
 * @property {Boolean} primary_contact
 * @property {Date} update_date
 */

/**
 *
 * @typedef LocationInfo
 * @type {Object}
 * @property {String} admin_area_1
 * @property {String} admin_area_2
 * @property {Number} country_code_id
 * @property {String} country_name
 * @property {String} description
 * @property {String} locality
 * @property {String} location_type
 * @property {String} postal_code
 */

/**
 *
 * @typedef Contact
 * @type {Object}
 * @property {Array<ContactDeviceInfo>} contact_information
 * @property {Array<LocationInfo>} location_information
 * @property {Array<CountryCodeInfo>} country_code
 */

/**
 *
 * @param {Contact} contact
 * @param {String} device_type
 * @returns {(String|Undefined)}
 */
export const getContactDevice = (contact, device_type) => {
  if (!contact.contact_information) {
    return;
  }

  const device = contact.contact_information
    .filter(Boolean)
    .find((info) => info.description === device_type);

  if (!device) {
    return;
  }

  const country = contact.country_code
    .filter(Boolean)
    .find((info) => info.id === device.device_country);
  return formatPhoneNumber(country.phone, device.device);
};

/**
 *
 * @param {Contact} contact
 * @param {String} location_type
 * @returns {(LocationInfo|Undefined)}
 */
export const getContactLocation = (contact, location_type) => {
  if (!contact.location_information) {
    return;
  }
  return contact.location_information
    .filter(Boolean)
    .find((info) => info.location_type === location_type);
};

export const displayableFullName = (c, includeMiddle = true) => {
  if (!c) {
    return '';
  }
  const middleNamePortion = !!c.middle_name
    ? ` ${capitalize(c.middle_name)}`
    : '';
  return `${capitalize(c.first_name)}${
    includeMiddle ? middleNamePortion : ''
  } ${capitalize(c.last_name)}`;
};

export const formatDuration = (start, end, isAddDuration = false) => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  const getDuration = (startDate, endDate) => {
    const end = isValid(endDate) ? endDate : new Date();
    // @ts-ignore
    return formatDistanceStrict(startDate, end, { includeSeconds: false });
  };

  const durationPortion = ` | ${getDuration(startDate, endDate)}`;

  const str = `${format(startDate, 'LLL yyyy')} - ${
    isValid(endDate) ? format(endDate, 'LLL yyyy') : 'Present'
  }${isAddDuration ? durationPortion : ''}`;

  return str;
};

export const formatReceived = (date) =>
  `Received on ${(date && defaultDateTimeZone(date, 'date')) || ''}`;
