import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const ExchangeInput = (props) => {
  const { selected, onChange } = props;
  return (
    <>
      <div className="exchange-header">Picture And PIN Exchange options</div>
      <FormControl component="fieldset" className="exchange" fullWidth>
        <RadioGroup
          aria-label="exchange"
          name="exchange"
          value={selected}
          onChange={onChange}
        >
          <FormControlLabel
            value="MOST_SECURE"
            control={<Radio color="primary" />}
            label="Exchange in person - Most secure (Zero-Knowledge)"
          />
          <FormControlLabel
            value="VERY_SECURE"
            control={<Radio color="primary" />}
            label="Exchange Picture using system and PIN Verbally (Very Secure)"
          />
          <FormControlLabel
            value="SECURE"
            control={<Radio color="primary" />}
            label="Exchange Picture using system and PIN SMS (Secure)"
          />
          <FormControlLabel
            value="LEAST_SECURE"
            control={<Radio color="primary" />}
            label="Exchange Picture and PIN between group using system to distribute (Very Secure Peer to Peer)"
          />
          <FormControlLabel
            value="NO_ENCRYPTION"
            control={<Radio color="primary" />}
            label="No encryption, no key required"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default ExchangeInput;
