import { sleepy, dumps, str2ab } from './webrtc';
import { store } from '../store';
import {
  setRemoteStream,
  setRemoteDevices,
  setCallPartner,
  setCallStarted,
  setLocalAudioEnabled,
  setLocalVideoEnabled,
  setLocalSelected,
  setRemoteEncryptEnabled,
  setCurrentKey,
  setUserList,
  setSignalingServerLogin,
  // setCallPartnerMemberId,
  setCallEndedByRemote,
  setEncryptionAvailable,
} from '../../redux/actions/one2onevcall';
import { setGroupCallCurrentKey } from '../../redux/actions/VideoCallRedux';

import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { getTwilioClientToken } from '../../redux/actions/member';

const location = window.location;
const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
const wsurl = protocol + '//' + location.host + '/iot-signal';
// const wsurl = protocol + '//localhost:9000/iot-signal';
// const STUN_SERVERS = 'stun:stun2.1.google.com:19302';
// SEE getIceServers!
// const STUN_SERVERS = ['stun:turn2.ameraiot.com:3478'];
// const TURN_SERVERS = ['turn:turn2.ameraiot.com:3478'];
const warnNotLoggedIn = async (who) => {
  console.log(`"${who}" is not logged in! �`);
};
export default class AmeraWebrtcClient {
  constructor(callType = 'contact', worker = null) {
    this.connection = null;
    this.name = '';
    this.worker = worker;
    this.partner = null;
    this.ameraAes = null;
    this.encryptEnabled = null;
    this.ws = null;
    this.findex = 0;
    this.bdata = new Uint8Array(0);
    this.remoteTracks = 0;
    this.callType = callType;
    this.stream = null;
  }

  connectWebsocket(user) {
    this.remoteTracks = 0;
    return new Promise((resolve, reject) => {
      const websocketConnection = new W3CWebSocket(wsurl);

      this.ws = websocketConnection;

      /** This handles websocket messages from signaling server
       *    WebRTC protocol is understood by signaling server and dispatched here
       *    user defined calls are processed onUserMessage
       *
       * @param {*} msg
       */
      websocketConnection.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        if (data.success !== undefined && data.success === false) {
          console.log(`failed: ${dumps(data)}`);
          // later - any handlers needed for faillure?
          if (['offer', 'login', 'candidate'].includes(data.type)) {
            if (data.type === 'offer') {
              warnNotLoggedIn(this.partner);
              this.connection = null;
            }
            console.log('dir dir dir dir');
            this.sendMessage('dir'); // refresh what callers are out there
          }
          return;
        } else if (data.type !== 'user') {
          console.log(`Got message: ${data.type}`);
        } else if (data.type !== 'getkey' && data.type !== 'user') {
          console.log(`msg: ${dumps(data)}`);
        }

        switch (data.type) {
          case 'login':
            this.handleLogin(data.success);
            break;
          case 'offer':
            this.handleOffer(data.offer, data);
            break;
          case 'answer':
            this.handleAnswer(data.answer);
            break;
          case 'candidate':
            this.handleCandidate(data.candidate);
            break;
          case 'getkey':
            this.handleGetkey(data.key);
            break;
          case 'dir':
            this.handleDir(data.dir);
            break;
          case 'echo':
            this.handleEcho(data);
            break;
          case 'user':
            this.onUserMessage(data.options);
            break;
          case 'close':
            this.handleClose(true, true);
            break;
          default:
            console.log(`unrecognized message type ${data.type}`);
            break;
        }
      };

      websocketConnection.onopen = () => {
        console.log(`Connected to the signaling server at ${wsurl}`);
        if (user !== null) {
          console.log(`ws.open() username good, ${dumps(user)}`);
          let mylogin = {
            type: 'login',
            username: user.username,
            member_id: user.member_id,
            first_name: user.first_name,
            last_name: user.last_name,
          };
          console.log(`send login in ws.onopen: ${dumps(mylogin)}`);
          this.sendMessage(mylogin);
          resolve(websocketConnection);
          return;
        }
        throw new Error('User not logged in');
      };

      websocketConnection.onerror = (err) => {
        console.error(err);
        // alert(`Signaling server is unavailable! �`);
        console.log('Signaling server is unavailable!');
        reject(err);
      };
    });
  }

  /**
   *  Message Handler for an offer.  This happens when a peer is offereing to connect
   *  1. the incoming username is our peer's name.  we will use that for sending messages to signaling server
   *  2. we save the WebRTC offer as our WebRTC remote description ( can be an offer or an answer)
   *  3. create an answer, set as our WebRTC local descriotion
   *  4. send the answer to the signaling server, who will relay to peer
   *  5.
   *
   */

  handleOffer(offer, data) {
    this.partner = data.username;
    let connectionPromise = Promise.resolve(this);
    if (!this.connection) {
      console.log('handleOffer: connection closed, get new one');
      connectionPromise = this.newConnection();
    }
    connectionPromise
      .then(() =>
        this.connection.setRemoteDescription(new RTCSessionDescription(offer))
      )
      .then(() =>
        // allow connection to caller
        // later will set caller in redux
        store.dispatch(
          setCallPartner({
            email: data.username,
            member_id: data.member_id,
            first_name: data.first_name,
            last_name: data.last_name,
          })
        )
      )
      .then(() => store.dispatch(setCallStarted(true)))
      .then(() => this.connection.createAnswer())
      .then(
        (answer) => {
          // debugger;
          this.sendMessage({
            type: 'answer',
            answer: answer,
          });
          this.connection.setLocalDescription(answer);
        },
        (error) => {
          // alert('Error when creating an answer');
          console.error('Error when creating an answer');
          console.error(error);
        }
      )
      .then(() =>
        this.sendUserMessage({
          type: 'devicequery',
          constraints: {
            video: true,
            audio: true,
          },
        })
      );
  }

  async handleLogin(success) {
    if (success)
      console.debug(
        '=============== logged in signaling server ===============>'
      );

    if (success === false) {
      console.error('😞 Login failed, restarting');
      // alert('😞 Login failed, restarting');
      this.handleClose(true);
    } else {
      if (this.callType === 'contact') {
        // this.newConnection(); unneccessary connection
        store.dispatch(setSignalingServerLogin(true));
        this.sendMessage('dir');
      }
    }
  }

  handleDir(userList) {
    console.log(`userList of users:\n${dumps(userList)}`);
    // later, will save logged in users to redux

    if (this.callType === 'contact') {
      store.dispatch(setUserList(userList));
    }
  }

  /**
   *  Message Handler for an answer.  This happens when a peer has answered our offer to connect
   *  1. we save the WebRTC answer as our WebRTC remote description ( can be an offer or an answer)
   *  2. customize close label
   *  3. send a device ping to show we are the user
   *
   */

  handleAnswer(answer) {
    console.log('got answer', answer);
    this.connection.setRemoteDescription(new RTCSessionDescription(answer));
    // connected with call partner
    store.dispatch(setCallStarted(true));

    // go ahead and ping for devices.
    const myconstraints = {
      video: true,
      audio: true,
    };
    this.sendUserMessage({
      type: 'devicequery',
      constraints: myconstraints,
    });
  }

  handleGetkey(key) {
    if (!key.success) {
      console.error(`Key generation failed\n${key.info}`);
      console.error(key);
      // alert(`Could not generate key 😉\n${key.info}`);
      return;
    }

    console.log('handleGetKey', key);

    if (this.callType === 'contact') {
      store.dispatch(setCurrentKey(key.keydata));
    } else {
      store.dispatch(setGroupCallCurrentKey(str2ab(key.keydata)));
    }
  }

  handleEcho(data) {
    console.log(`echo:\n${dumps(data)}`);
  }

  /** onUserMessage handles user defined commands.  server doesn't have to change as these are added
   *
   */

  async onUserMessage(options) {
    // if we are using encryption, try to decrypt.  partner may not be turned on
    try {
      if (this.encryptEnabled && this.ameraAes) {
        options = await this.ameraAes.decrypt(options, {
          mode: 'json',
          verbose: true,
          cipher: 'utf8',
        });
        console.log(`partner is encrypted`);
      }
    } catch (error) {
      console.log(
        `onUserMessage: : ${error.name}, ${error.message} ${error.stack}`
      );
      console.log(`is partner unencrypted? `);
    }

    try {
      console.log(`Got user message: ${options.type}`);
    } catch (error) {
      console.log(
        `onUserMessage: : ${error.name}, ${error.message} ${error.stack}`
      );
      console.log(`msg: ${dumps(options)}`);
    }

    switch (options.type) {
      case 'devicequery':
        this.handleDeviceQuery(options.constraints);
        break;
      case 'devices':
        this.handleDevices(options.devices);
        break;
      case 'changedevices':
        this.handleDeviceSelect(options.select);
        break;
      case 'toggletrack':
        this.handleToggleTrack(options.toggle);
        break;
      case 'toggleencryption':
        this.handleToggleEncryption(options.toggle);
        break;
      case 'xferfile':
        this.handleXferfile(options.filedata);
        break;
      // add new commands here
      default:
        console.log("can't recognize msg, is partner encrypted?");
        break;
    }
  }

  handleClose(logoff = true, remote = false) {
    console.log('got handle Close', logoff);
    console.log('=_+++++++++', remote);
    if (logoff === false) this.sendMessage('dir'); //refresh choices
    console.log('remote', remote);
    if (!remote)
      this.sendMessage({
        type: 'close',
        logoff,
      });
    this.partner = null;
    if (this.connection) {
      this.connection.close();
      this.connection.onicecandidate = null;
      this.connection.ontrack = null;
      this.connection = null;
    }
    this.findex = 0;
    this.bdata = new Uint8Array(0);
    this.remoteTracks = 0;

    if (remote) store.dispatch(setCallEndedByRemote(true));
    // store.dispatch(setCallPartner({ email: '' }));
    store.dispatch(setCallStarted(false));
    // store.dispatch(setCallPartnerMemberId(null, null));
    //window.location.reload(false);
  }

  // may be a remote query or async
  // find out local devices and pass the result to remote, minus local info
  handleDeviceQuery(constraints) {
    const state = store.getState();
    const localSelected = state.one2onevcall.localSelected;
    console.log('constraints', constraints);
    navigator.mediaDevices
      // .enumerateDevices(constraints)
      .enumerateDevices()
      .then((devices) =>
        devices.map((device) => ({
          kind: device.kind,
          label: device.label,
          deviceId: device.deviceId,
          selected: localSelected[device.kind] === device.label,
        }))
      )
      .then((myDevices) => {
        const myOptions = {
          type: 'devices',
          devices: myDevices,
        };
        this.sendUserMessage(myOptions);
        // Convention is callee is the webcam, 1st packet from user is devices
        // if (iAmWebcam === null) {
        //   iAmWebcam = true;
        // document.getElementsByTagName("H1")[0].innerHTML = "Amera Webcam!";
        // }
      })
      .catch(function (error) {
        console.log(
          `handleDeviceQuery: ${error.name}, ${error.message} ${error.stack}`
        );
      });
  }

  // this is the answer to querydevices or just async, as with a device on initial connect
  handleDevices(devices) {
    store.dispatch(setRemoteDevices(devices));
    // we don't get resolutions from device, we just create what we want
  }

  // this is the remote asking to select devices locally
  handleDeviceSelect(select) {
    const localSelected = store.getState().one2onevcall.localSelected;
    store.dispatch(setLocalSelected({ ...localSelected, ...select.devices }));
  }

  handleToggleTrack(toggle) {
    if (toggle.kind === 'audioinput') {
      store.dispatch(setLocalAudioEnabled(toggle.enabled));
    } else if (toggle.kind === 'videoinput') {
      store.dispatch(setLocalVideoEnabled(toggle.enabled));
    }
  }

  handleCandidate(candidate) {
    let connectionPromise = Promise.resolve(this);
    if (!this.connection) {
      connectionPromise = this.newConnection();
    }
    connectionPromise
      .then(() =>
        this.connection.addIceCandidate(new RTCIceCandidate(candidate))
      )
      .catch((error) => {
        console.log(
          `Failure during addIceCandidate(): ${error.name}, ${error.message} ${error.stack}`
        );
      });
  }

  /** Handler for when remote has toggled encryption.  we pause stream to force an IFrame
   *
   * @param {*} toggle
   */
  async handleToggleEncryption(toggle) {
    // console.log(`handleToggleEncryption:\n${JSON.stringify(toggle, null, 2)}`);
    store.dispatch(setRemoteEncryptEnabled(toggle.enabled));
  }
  /**
   *  handler for user message for file transfer.  file comes in as bas64 slices, gets decoded, assembled
   *  and finally stored as a download
   * @param {*} filedata
   */
  handleXferfile = (filedata) => {
    if (filedata.index === 0) {
      this.findex = 0;
      this.bdata = new Uint8Array(filedata.length);
    }
    // first, we look store data into buffer
    try {
      const byteCharacters = atob(filedata.rawbytes);
      const bslice = new Array(byteCharacters.length);
      console.log(
        `slice length - base64:  ${filedata.rawbytes.length} bin ${bslice.length} `
      );
      for (let i = 0; i < byteCharacters.length; i++) {
        bslice[i] = byteCharacters.charCodeAt(i);
      }
      this.bdata.set(bslice, this.findex);
      this.findex += byteCharacters.length;
    } catch (error) {
      console.log(
        `handleXferfile-block: : ${error.name}, ${error.message} ${error.stack}`
      );
    }
    // at the last slice, then save a download file
    if (filedata.more === false) {
      try {
        let blob = new Blob([this.bdata], { type: filedata.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filedata.name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 100);
      } catch (error) {
        console.log(
          `handleXferfile-save: ${error.name}, ${error.message} ${error.stack}`
        );
      }
    }
  };

  /** sendUserMessage sends user defined websocket messages to signalining server
   *    the inner contents may be encrypted
   *
   */
  sendUserMessage = async (options) => {
    let myoptions = options;
    let display; // has to be in the clear for signaling server to understand
    if (myoptions.display === false) {
      display = false;
      console.log(`sendUserMessage: ${JSON.stringify(myoptions, null, 2)}`);
    } else {
      display = true;
      console.log(`sendUserMessage: ${myoptions.type}`);
    }

    try {
      // if doing E2EE encryption, this is where we encrypt outbound
      if (this.encryptEnabled && myoptions.type !== 'toggleencryption') {
        console.log(`sendUserMessage: encypting ${myoptions.type}`);
        myoptions = await this.ameraAes.encrypt(myoptions, {
          mode: 'json',
          verbose: true,
          cipher: 'utf8',
        });
      }
    } catch (error) {
      console.log(
        `sendUserMessage: : ${error.name}, ${error.message} ${error.stack}`
      );
    } finally {
      this.sendMessage({
        type: 'user',
        display: display,
        options: myoptions,
      });
    }
  };

  /**
   *
   * @returns {Promise<Array<Object>>}
   */
  static async getIceServers() {
    try {
      const client_token = await getTwilioClientToken();
      return client_token.token.ice_servers;
    } catch (e) {
      const turn_username = 'turnguest';
      const turn_password = 'Amer@share';
      const STUN_SERVERS = ['stun:turn2.ameraiot.com:3478'];
      const TURN_SERVERS = ['turn:turn2.ameraiot.com:3478'];

      return [
        {
          urls: STUN_SERVERS,
          username: turn_username,
          credential: turn_password,
        },
        {
          urls: TURN_SERVERS,
          username: turn_username,
          credential: turn_password,
        },
      ];
    }
  }

  async newConnection() {
    // const secret =
    //   '482a5b3ea90ef8f246fa5ccc5efd450559643710a1141ba504c65a44da9e9bb6';

    const configuration = {
      iceServers: await AmeraWebrtcClient.getIceServers(),
    };

    const state = store.getState();
    const stream = state.one2onevcall.localStream;
    let encryptionAvailable = state.one2onevcall.encryptionAvailable;

    if (
      !!RTCRtpSender.prototype.createEncodedStreams &&
      encryptionAvailable &&
      stream
    ) {
      configuration.encodedInsertableStreams = true;
    }

    this.connection = new RTCPeerConnection(configuration);

    if (stream) {
      // this will not make race
      for (const track of stream.getTracks()) {
        this.connection.addTrack(track, stream);
      }
    }

    // if(this.stream) {
    //   for (const track of this.stream.getTracks()) {
    //     this.connection.addTrack(track, this.streamstream);
    //   }
    // }

    // this.connection.onended = () => console.log('Stream ended');

    this.connection.ontrack = async (event) => {
      if (configuration.encodedInsertableStreams && encryptionAvailable)
        this.setupReceiverTransform(event.receiver); //new for E2EE

      this.remoteTracks++;
      if (this.remoteTracks > 1) {
        event.track.onunmute = () => {
          store.dispatch(setRemoteStream(event.streams[0]));
        };
      }
    };

    this.connection.onicecandidate = (event) => {
      if (this.partner === null) {
        console.log(`partner is null, abort sending candidate`);
        return;
      }
      if (event.candidate) {
        this.sendMessage({
          type: 'candidate',
          candidate: event.candidate,
        });
      }
    };

    this.connection.onconnectionstatechange = async (event) => {
      // yiangos, update for current UI
      switch (this.connection.connectionState) {
        case 'connected':
          // The connection has become fully connected
          const local = await this.connStats(); // we will find out if TURN was needed
          this.connection.candidateType = local.candidateType;
          this.connection.connectionType =
            this.connection.candidateType === 'RELAY' ? 'TURN' : 'DIRECT';
          this.connection.connectionCandidate = local;
          switch (local.candidateType) {
            case 'host':
              console.log(
                `type: ${local.candidateType} IP ${local.ip} true remote (no TURN)`
              );
              this.connection.ipString = `Connect Local IP - ${local.ip}`;
              break;
            case 'srflx':
              console.log(
                `type: ${local.candidateType} IP ${local.ip} server reflexsive intermediate assigned by STUN(no TURN)`
              );
              this.connection.ipString = `Connect STUN mapped IP - ${local.ip}`;
              break;
            case 'prflx':
              console.log(
                `type: ${local.candidateType} IP ${local.ip} server peer reflexsive intermediate assigned by STUN(no TURN)`
              );
              break;
            case 'relay':
              console.log(
                `type: ${local.candidateType} IP ${local.ip} relay from TURN server`
              );
              this.connection.ipString = `Connect TURN server IP - ${local.ip}`;
              break;
            default:
              break;
          }
          break;
        case 'disconnected':
          console.log('connection disconnected');
          break;
        case 'failed':
          // One or more transports has terminated unexpectedly or in an error
          console.log('connection failed');
          break;
        case 'closed':
          console.log('connection closed');
          // The connection has been closed
          break;
        default:
          break;
      }
    };

    let needToContinue = true;
    //    new for E2EE
    if (configuration.encodedInsertableStreams && encryptionAvailable) {
      let senders = this.connection.getSenders();
      for (let i = 0; i < senders.length; i++) {
        needToContinue = await this.setupSenderTransform(senders[i]);
        if (!needToContinue) break;
      }
    }
    if (!needToContinue) return this;
    return this;
  }

  connStats = async () => {
    await sleepy(200); // let it settle, can say in progress, want succeeded
    if (!this.connection) return;
    const stats = await this.connection.getStats();

    let localCandidates = [];
    let candidatePair;

    // stats has amazing amount of crap, don't care about mostly
    for (let val of stats.values()) {
      //console.log(`type: ${val.type}  ${dumps(val)}`)

      // candidate-pair lets us know if it was selected, provides the local-candidate's id
      if (val.type === 'candidate-pair') {
        //console.log(`state: ${val.state}`);
        if (val.state === 'succeeded') {
          //console.log(`\ntype: ${val.type}  ${dumps(val)}`);
          //console.log(`\n   state: ${val.state} this pair in use`);
          candidatePair = val;
        }
      }

      // local-candidate lets us know what type, TURN or otherwise
      else if (val.type === 'local-candidate') {
        //console.log(`\ntype: ${val.type}  ${dumps(val)}`);
        localCandidates.push(val);
      }
    }
    for (let local of localCandidates) {
      console.log(
        `local id ${local.id}, candidate-pair id ${candidatePair.localCandidateId}`
      );
      if (local.id === candidatePair.localCandidateId) {
        return local;
      }
    }
  };

  // added for E2EE
  async setupSenderTransform(sender) {
    // debugger;
    try {
      let senderStreams = sender.createEncodedStreams();
      // Instead of creating the transform stream here, we do a postMessage to the worker. The first
      // argument is an object defined by us, the second a list of variables that will be transferred to
      // the worker. See
      //   https://developer.mozilla.org/en-US/docs/Web/API/Worker/postMessage

      this.worker.postMessage(
        {
          operation: 'encode',
          readableStream: senderStreams.readable,
          writableStream: senderStreams.writable,
        },
        [senderStreams.readable, senderStreams.writable]
      );
      await store.dispatch(setEncryptionAvailable(true));
      return true;
    } catch (e) {
      await store.dispatch(setEncryptionAvailable(false));
      await this.newConnection();
      return false;
    }
  }

  setupReceiverTransform(receiver) {
    try {
      let receiverStreams = receiver.createEncodedStreams();

      this.worker.postMessage(
        {
          operation: 'decode',
          readableStream: receiverStreams.readable,
          writableStream: receiverStreams.writable,
        },
        [receiverStreams.readable, receiverStreams.writable]
      );
    } catch (e) {
      console.log('Not decode');
      console.log(e);
    }
  }

  sendMessage(message) {
    if (typeof message === 'string')
      message = {
        type: message,
      }; // object has no other parameters
    if (this.partner) {
      message.otherUsername = this.partner;
    }
    if (this.ws && this.ws.readyState === 1)
      this.ws.send(JSON.stringify(message));
  }

  call(partner) {
    this.partner = partner;
    let connectionPromise = Promise.resolve(this);
    if (!this.connection) {
      connectionPromise = this.newConnection();
    }
    connectionPromise
      .then(() => this.connection.createOffer())
      .then(
        (offer) => {
          this.sendMessage({
            type: 'offer',
            offer: offer,
          });

          this.connection.setLocalDescription(offer);
        },
        (error) => {
          // alert('Error when creating an offer');
          console.error('Error when creating an offer');
          console.error(error);
        }
      );
  }
}
