import React, { useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
// import { PropTypes } from 'prop-types';
// Material ui
import { Grid } from '@material-ui/core/';
import { getNodes } from '../../utils/fileshare';
// import FileButtonGroup from './FileButtonGroup';
import FileDetailModal from './Modals/FileDetail/FileDetail';
import EncryptFile from './Modals/EncryptFile';
import DecryptFile from './Modals/DecryptFile';
import UploadFile from './Modals/UploadFile';
import Rename from './Modals/Rename';
import AddFolder from './Modals/AddFolder';
import ShareProcess from './Modals/ShareProcess/ShareProcess';
import DeleteFile from './Modals/DeleteFile';
import Duplicates from './Modals/Duplicates';
import ClipBoard from './ClipBoard';
import FileShareSpinner from './FileShareSpinner';

import { includes } from 'lodash';

import {
  ChonkyActions,
  FileBrowser,
  FileList,
  FileToolbar,
  FileContextMenu,
  FileNavbar,
  setChonkyDefaults,
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

import {
  putCopySharedFile,
  putModifyNodes,
  postUploadFiles,
  deleteFile,
  getDownloadFile,
} from '../../redux/actions/fileshare';

import {
  keepBothName,
  useFiles,
  useFolderChain,
  uploadFileAction,
  downloadFileAction,
  shareFileAction,
  copySharedFile,
  createFolderAction,
  renameAction,
  cutFileAction,
  copyFileAction,
  pasteFileAction,
  deleteFileAction,
  showInfoAction,
  customFormat,
} from '../../utils/fileshare';
const shortid = require('shortid');
// import { nonBrowserFeatureState } from '../../utils/non-browser-state';

setChonkyDefaults({ iconComponent: ChonkyIconFA, i18n: customFormat });

const MemberMainTree = (props) => {
  const { dispatch, fileTree, defaultViewActionId, defaultSortActionId } =
    props;
  const [_isLoading, _setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(fileTree.rootFolderId);
  const [clipboard, setClipboard] = useState([]);
  const [duplicates, setDuplicates] = useState(null);
  const [isShowClipboard, setShowClipboard] = useState(false);

  // Modals
  const [showAddFolderModal, setShowAddFolderModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showEncryptLocalModal, setShowEncryptLocalModal] = useState(false);
  const [showDecryptFileModal, setShowDecryptFileModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicatesModal, setShowDuplicatesModal] = useState(false);

  const fileBrowserRef = useRef(null);

  // const handleEncryptLocalClick = () => {
  //   // console.log('Clicked ecnrypt local');
  //   setShowEncryptLocalModal(true);
  // };

  // const handleDecryptLocalClick = () => {
  //   // console.log('Clicked decrypt local');
  //   setShowDecryptFileModal(true);
  // };

  // If the user clicks paste while having a folder selected => the file ends up in that folder and not current folder
  const handlePasteClipboard = useCallback(() => {
    if (clipboard.length > 0) {
      console.log('Clipboard is not empty', selectedFiles);

      const targetFolderId =
        selectedFiles[0] && selectedFiles[0].isDir
          ? selectedFiles[0].id
          : currentFolderId;

      const siblingNodes = getNodes(fileTree, targetFolderId);
      const siblingNames = siblingNodes.map((f) => f.name);
      const incomingDuplicates = clipboard
        .filter((f) => includes(siblingNames, f.name))
        .map((d) => ({
          ...d,
          counterpart_node_id: siblingNodes.find((n) => n.name === d.name).id,
          targetFolderId: targetFolderId,
        })); // We add information over the existing node that causes duplicity

      if (incomingDuplicates.length > 0) {
        /*
          If we have duplicates, we have to decide how to deal with them first
        */
        setDuplicates(incomingDuplicates);
        setShowDuplicatesModal(true);
      } else {
        /*
          If no duplicates => we simply put all of clipboard
        */

        const changes = clipboard.map((f) => {
          return {
            node_id: f.id,
            file_id: f.file_id,
            isDir: f.isDir,
            parentId: targetFolderId,
            name: f.name,
            isKeepOriginal: f.isKeepOriginal, // This let us know what to do with the original node that is located where we copied/cut it in the first place
            deleteId: null,
          };
        });
        _setLoading(true);
        dispatch(putModifyNodes(changes));
        _setLoading(false);
        setClipboard([]);
      }
    } else {
      console.log('Clipboard is empty');
    }
  }, [clipboard, currentFolderId, dispatch, fileTree, selectedFiles]);

  const handleSyncClick = (isOverWriting, movedFiles = clipboard) => {
    const duplicateIds = duplicates.map((d) => d.id);
    const targetFolderId = duplicates[0].targetFolderId; // Apparently a duplicate object has information over it's parent
    let changes;
    if (isOverWriting) {
      console.log('Will overwrite duplicates', duplicateIds, movedFiles);
      /*
        Some files (not all of them)  within clipboard should overwrite counterparts in the target folder.
      */
      changes = movedFiles.map((f) =>
        includes(duplicateIds, f.id)
          ? {
              node_id: f.id,
              file_id: f.file_id,
              isDir: f.isDir,
              parentId: targetFolderId,
              name: f.name,
              isKeepOriginal: f.isKeepOriginal,
              deleteId: duplicates.find((d) => d.id === f.id)
                .counterpart_node_id, // This will let backend know that we want to delete the counterpart
            }
          : {
              node_id: f.id,
              file_id: f.file_id,
              isDir: f.isDir,
              parentId: targetFolderId,
              name: f.name,
              isKeepOriginal: f.isKeepOriginal,
              deleteId: null,
            }
      );

      console.log('Changes when overwriting', changes);
    } else {
      console.log('Will rename files', duplicateIds, movedFiles);
      changes = movedFiles.map((f) =>
        includes(duplicateIds, f.id)
          ? {
              node_id: f.id,
              file_id: f.file_id,
              isDir: f.isDir,
              parentId: targetFolderId,
              name: keepBothName(f.name), // this lets us rename the moved node
              isKeepOriginal: f.isKeepOriginal,
              deleteId: null,
            }
          : {
              node_id: f.id,
              file_id: f.file_id,
              isDir: f.isDir,
              parentId: targetFolderId,
              name: f.name,
              isKeepOriginal: f.isKeepOriginal,
              deleteId: null,
            }
      );
      console.log('Changes when renaming', changes);
    }
    _setLoading(true);
    dispatch(putModifyNodes(changes));
    _setLoading(false);
  };

  const handleShareIntent = useCallback((filesArr) => {
    console.log('filesArr', filesArr);
    setSelectedFiles(filesArr);
    setShowShareModal(true);
  }, []);

  const handleFileAction = useCallback(
    (data) => {
      const { id, state, payload } = data;
      const { selectedFilesForAction } = state;
      if (id === ChonkyActions.OpenFiles.id) {
        if (payload.targetFile.isDir) {
          setCurrentFolderId(payload.targetFile.id);
        }
      } else if (id === uploadFileAction.id) {
        setShowUploadModal(true);
      } else if (id === createFolderAction.id) {
        setShowAddFolderModal(true);
      } else if (id === renameAction.id) {
        setSelectedFiles(selectedFilesForAction);
        setShowRenameModal(true);
        // } else if (id === ChonkyActions.ChangeSelection) {
        //   setSelectedFiles(selectedFilesForAction);
      } else if (id === copyFileAction.id) {
        //  When we copy we add a field isCopy: true
        setClipboard(
          selectedFilesForAction.map((f) => ({ ...f, isKeepOriginal: true }))
        );
        setShowClipboard(true);
      } else if (id === cutFileAction.id) {
        //  When we cut, we mark isCopy: false
        setClipboard(
          selectedFilesForAction.map((f) => ({ ...f, isKeepOriginal: false }))
        );
        setShowClipboard(true);
      } else if (id === pasteFileAction.id) {
        // console.log('clipboard pasted', clipboard);
        handlePasteClipboard();
      } else if (id === shareFileAction.id) {
        handleShareIntent(selectedFilesForAction);
      } else if (id === deleteFileAction.id) {
        setSelectedFiles(selectedFilesForAction);
        setShowDeleteModal(true);
      } else if (id === downloadFileAction.id) {
        const { amera_file_url, name } = selectedFilesForAction[0];
        _setLoading(true);
        getDownloadFile(amera_file_url, name);
        _setLoading(false);
      } else if (id === ChonkyActions.MoveFiles.id) {
        const targetFolderId = payload.destination.id;
        const siblingNodes = getNodes(fileTree, targetFolderId);
        const siblingNames = siblingNodes.map((f) => f.name);
        setClipboard(
          selectedFilesForAction.map((f) => ({ ...f, isKeepOriginal: false }))
        );
        const incomingDuplicates = selectedFilesForAction
          .filter((f) => includes(siblingNames, f.name))
          .map((d) => ({
            ...d,
            counterpart_node_id: siblingNodes.find((n) => n.name === d.name).id,
            targetFolderId: targetFolderId,
          })); // We add information over the existing node that causes duplicity

        if (incomingDuplicates.length > 0) {
          /*
          If we have duplicates, we have to decide how to deal with them first
        */
          setDuplicates(incomingDuplicates);
          setShowDuplicatesModal(true);
        } else {
          /*
          If no duplicates => we simply put all of clipboard
        */

          const changes = selectedFilesForAction.map((f) => {
            return {
              node_id: f.id,
              file_id: f.file_id,
              isDir: f.isDir,
              parentId: targetFolderId,
              name: f.name,
              isKeepOriginal: false, // This let us know what to do with the original node that is located where we copied/cut it in the first place
              deleteId: null,
            };
          });
          _setLoading(true);
          dispatch(putModifyNodes(changes));
          _setLoading(false);
          setClipboard([]);
        }
      } else if (id === copySharedFile.id) {
        const nodeIds = selectedFilesForAction.map((f) => f.id);
        console.log('Will copy nodes', nodeIds);
        dispatch(
          putCopySharedFile(
            JSON.stringify({
              node_ids: nodeIds,
              current_folder_id: currentFolderId,
            })
          )
        );
      } else if (id === showInfoAction.id) {
        setSelectedFiles(selectedFilesForAction);
        setShowDetailModal(true);
      }
    },
    [
      handlePasteClipboard,
      handleShareIntent,
      fileTree,
      dispatch,
      currentFolderId,
    ]
  );

  const handleFolderCreateConfirm = async (folderName) => {
    let formData = new FormData();

    formData.set(
      'metadata',
      JSON.stringify({
        tagetFolderId: currentFolderId,
        nodesToDelete: [],
        meta: [
          {
            node_temp_id: shortid.generate(),
            name: folderName,
            isDir: true,
            level: 0,
            size: null,
            iv: null,
            parentId: null,
          },
        ],
      })
    );

    await dispatch(postUploadFiles(formData, '/drive/member/files'));
  };

  const handleDeleteConfirm = async (nodeIds) => {
    await dispatch(deleteFile(nodeIds));
  };

  const fileActions = [
    createFolderAction,
    uploadFileAction,
    downloadFileAction,
    shareFileAction,
    showInfoAction,
    copyFileAction,
    cutFileAction,
    pasteFileAction,
    deleteFileAction,
    renameAction,
    copySharedFile,
  ];

  // console.log('set show file details', showDetailModal);
  // console.log('selected files', selectedFiles);

  return (
    <div className="member-files-table file-table">
      {duplicates && (
        <Duplicates
          show={showDuplicatesModal}
          close={() => setShowDuplicatesModal(false)}
          duplicates={duplicates}
          onSyncClick={handleSyncClick}
        />
      )}

      {clipboard && (
        <ClipBoard
          isOpen={isShowClipboard}
          onClose={() => setShowClipboard(false)}
          clipboard={clipboard}
        />
      )}

      <AddFolder
        fileTree={fileTree}
        show={showAddFolderModal}
        close={() => setShowAddFolderModal(false)}
        currentFolderId={currentFolderId}
        onNameConfirm={handleFolderCreateConfirm}
      />

      <Rename
        show={showRenameModal}
        close={() => setShowRenameModal(false)}
        selectedFile={selectedFiles[0]}
        currentFolderId={currentFolderId}
      />

      <EncryptFile
        show={showEncryptLocalModal}
        close={() => setShowEncryptLocalModal(false)}
      />

      <DecryptFile
        show={showDecryptFileModal}
        close={() => setShowDecryptFileModal(false)}
      />
      <UploadFile
        show={showUploadModal}
        fileTree={fileTree}
        path={'/drive/member/files'}
        currentFolderId={currentFolderId}
        close={() => setShowUploadModal(false)}
      />

      {selectedFiles[0] ? (
        <FileDetailModal
          show={showDetailModal}
          close={() => setShowDetailModal(false)}
          selectedFile={selectedFiles[0]}
          onShareAction={handleShareIntent}
        />
      ) : null}

      <ShareProcess
        show={showShareModal}
        close={() => setShowShareModal(false)}
        files={selectedFiles}
      />

      <DeleteFile
        show={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        files={selectedFiles}
        onDeleteConfirm={handleDeleteConfirm}
      />

      <Grid container justify="center" spacing={3} className="h-100">
        <Grid item xs={12} className="file-browser-container">
          <FileShareSpinner isLoading={_isLoading} />
          <FileBrowser
            ref={fileBrowserRef}
            files={useFiles(currentFolderId, fileTree)}
            disableDefaultFileActions={[
              ChonkyActions.ClearSelection.id,
              ChonkyActions.SelectAllFiles.id,
              ChonkyActions.OpenSelection.id,
              ChonkyActions.ToggleHiddenFiles.id,
            ]}
            defaultSortActionId={defaultSortActionId}
            defaultFileViewActionId={defaultViewActionId}
            fileActions={fileActions}
            folderChain={useFolderChain(currentFolderId, fileTree)}
            onFileAction={handleFileAction}
          >
            <FileNavbar />
            <FileToolbar />
            <FileList />
            <FileContextMenu />
          </FileBrowser>
        </Grid>
        <Grid item xs>
          {/* <FileButtonGroup
            isDecryptUponDownloadAvailable={
              isEncryptedSelected && nonBrowserFeatureState
            }
            selectedKeys={selectedFileIds}
            onUpload={handleUploadClick}
            onLocalEncrypt={
              nonBrowserFeatureState ? handleEncryptLocalClick : null
            }
            onDecryptLocal={
              nonBrowserFeatureState ? handleDecryptLocalClick : null
            }
            onDelete={handleDeleteClick}
            onDetail={handleClickShowDetailModal}
            onDisplayToggle={handleDisplayModeToggle}
            displayMode={displayMode}
            onDownload={handleDownloadFile}
            onShare={() => setShowShareModal(true)}
          /> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default connect()(MemberMainTree);
