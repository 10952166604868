import React from 'react';
import Modal from './modal';
import theme from '../../jss/theme';

// Material
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const helpModalStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));
export const HelpModal = ({ open, onClose }) => {
  const helpModalClasses = helpModalStyles();
  const title = 'Help';
  const HelpModalContent = () => (
    <div className="profile_page-body">
      <ThemeProvider theme={theme}>
        <div>
          If you are experiencing a problem contact AmeraShare support at{' '}
          <Link
            color="primary"
            target="_blank"
            href="mailto:support@tetherit.com"
          >
            support@tetherit.com
          </Link>
          .
        </div>
      </ThemeProvider>
    </div>
  );

  const HelpModalAction = () => {
    return (
      <div className={helpModalClasses.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={helpModalClasses.actionBtn}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      ModalContent={HelpModalContent}
      ModalAction={HelpModalAction}
    ></Modal>
  );
};
