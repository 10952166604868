export const STROPHE_CONNECT = 'STROPHE_CONNECT';
export const STROPHE_DISCONNECT = 'STROPHE_DISCONNECT';
export const STROPHE_CONNECTION_STATUS = 'STROPHE_CONNECTION_STATUS';

export const SUBSCRIBE_MEMBERS = 'SUBSCRIBE_MEMBERS';
export const AUTHORIZE_MEMBERS = 'AUTHORIZE_MEMBERS';
export const UNAUTHORIZE_MEMBERS = 'UNAUTHORIZE_MEMBERS';
export const UNSUBSCRIBE_MEMBERS = 'UNSUBSCRIBE_MEMBERS';

export const SET_CHAT_CONVERSATION_ID = 'SET_CHAT_CONVERSATION_ID';
export const SET_GROUP_CONVERSATION_ID = 'SET_GROUP_CONVERSATION_ID';

export const LOAD_CONTACTS_ARCHIVE = 'LOAD_CONTACTS_ARCHIVE';

export const LOAD_ROSTER_ITEMS = 'LOAD_ROSTER_ITEMS';

export const CREATE_CHAT_ROOM = 'CREATE_CHAT_ROOM';
export const JOIN_CHAT_ROOM = 'JOIN_CHAT_ROOM';
export const LEAVE_CHAT_ROOM = 'LEAVE_CHAT_ROOM';
export const DESTROY_CHAT_ROOM = 'DESTROY_CHAT_ROOM'; //for middleware
export const DESTROY_CHAT_ROOMS = 'DESTROY_CHAT_ROOMS'; //for middleware
export const DELETE_GROUP_CHAT_ROOM = 'DELETE_GROUP'; //for reducer
export const UPDATE_GROUP_ROSTERS = 'UPDATE_GROUP_ROSTERS';
export const SET_GROUP_ROSTER_STATUS = 'SET_GROUP_ROSTER_STATUS';

export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE';
export const SEND_GROUP_MESSAGE = 'SEND_GROUP_MESSAGE';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const ADD_GROUP_MESSAGE = 'ADD_GROUP_MESSAGE';
export const DECRYPT_MESSAGE = 'DECRYPT_MESSAGE';
export const DECRYPT_GROUP_MESSAGE = 'DECRYPT_GROUP_MESSAGE';
export const LOAD_CONTACT_MESSAGES = 'LOAD_CONTACT_MESSAGES';

export const SET_CHAT_UNREAD_MESSAGE_COUNT = 'SET_CHAT_UNREAD_MESSAGE_COUNT';

export const STROPHE_SEND_FILES = 'STROPHE_SEND_FILES';

export const CHAT_RESET = 'CHAT_RESET';

export const SET_MESSAGE_AS_READ = 'SET_MESSAGE_AS_READ';

export const CREATE_DEFAULT_CHAT_ROOM = 'CREATE_DEFAULT_CHAT_ROOM';

export const LOAD_GROUP_MESSAGES = 'LOAD_GROUP_MESSAGES';

export const INVITE_MEMBER = 'INVITE_MEMBER';

export const KICK_MEMBER = 'KICK_MEMBER';
