import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import MaterialAvatar from '../../../../../../components/MaterialAvatar';
import { displayableFullName } from '../../../../../../utils/contact';
import { getContactOnlineStatus } from '../../../../../../utils/strophe/user';

function Contact({
  contact,
  rosterItems,
  selectedContactMemberID,
  setSelectedContactMemberID,
}) {
  const contactName = displayableFullName(contact);
  const onlineStatus = getContactOnlineStatus(contact, rosterItems);
  const selectContact = () => {
    if (contact.contact_member_id === selectedContactMemberID) return;
    setSelectedContactMemberID(contact.contact_member_id.toString());
  };

  return (
    <ListItem
      key={contact.id}
      button
      disableRipple
      alignItems="flex-start"
      className="contact-item"
      selected={contact.contact_member_id === selectedContactMemberID}
      onClick={selectContact}
    >
      <ListItemAvatar>
        <MaterialAvatar
          badgeType={onlineStatus}
          badgeVariant="dot"
          classes="contact-profile-avatar"
          member_id={contact.contact_member_id}
          src={contact.amera_avatar_url}
          firstName={contact.first_name}
          lastName={contact.last_name}
        />
      </ListItemAvatar>
      <ListItemText
        primary={contactName}
        // secondary={contact.latestMessageText}
      />
    </ListItem>
  );
}

export default Contact;
