export const messages = {
  dashboard: {
    id: 'app.dashboard.title',
    defaultMessage: 'Dashboard',
  },
  contacts: {
    id: 'app.contacts.title',
    defaultMessage: 'Contacts',
  },
  calls: {
    id: 'app.calls.title',
    defaultMessage: 'Calls',
  },
  group: {
    id: 'app.groups.title',
    defaultMessage: 'Groups',
  },
  streaming: {
    id: 'app.streaming.title',
    defaultMessage: 'Streaming',
  },
  conferencing: {
    id: 'app.conferencing.title',
    defaultMessage: 'Conferencing',
  },
  telephony: {
    id: 'app.telephony.title',
    defaultMessage: 'Telephony',
  },
  chat: {
    id: 'app.chat.title',
    defaultMessage: 'Chat',
  },
  mail: {
    id: 'app.mail.title',
    defaultMessage: 'Mail',
  },
  fileSharing: {
    id: 'app.fileSharing.title',
    defaultMessage: 'Drive',
  },
  calendar: {
    id: 'app.calendar.title',
    defaultMessage: 'Calendar',
  },
  projects: {
    id: 'app.projects.title',
    defaultMessage: 'Projects',
  },
  transaction: {
    id: 'app.transaction.title',
    defaultMessage: 'Billing',
  },
  communication: {
    id: 'app.communication.title',
    defaultMessage: 'IoT Device Control',
  },
  logout: {
    id: 'app.header.menu.logout',
    defaultMessage: 'Log Out',
  },
  mediaStreaming: {
    id: 'app.mediaStreaming.title',
    defaultMessage: 'Media Streaming',
  },
  mediaScheduler: {
    id: 'app.mediaScheduler.title',
    defaultMessage: 'Media Streaming Scheduler',
  },
  projectManagement: {
    id: 'app.projectManagement.title',
    defaultMessage: 'Project Management',
  },
  contactCall: {
    id: 'app.contactCall.title',
    defaultMessage: 'Contact Call',
  },
  groupCall: {
    id: 'app.groupCall.title',
    defaultMessage: 'Group Call',
  },
  groupChat: {
    id: 'app.groupChat.title',
    defaultMessage: 'Group Chat',
  },
  groupForums: {
    id: 'app.groupForums.title',
    defaultMessage: 'Group Forums',
  },
  historyAndAnalytics: {
    id: 'app.historyAndAnalytics.title',
    defaultMessage: 'History and Analytics',
  },
};

const navItems = [
  {
    id: 'dashboard',
    icon: 'dashboard',
    label: messages.dashboard,
    to: '/dashboard',
    notification: 0,
    menuItems: [],
  },
  {
    id: 'contacts',
    icon: 'contacts',
    to: '/contacts',
    btnLabel: '',
    label: messages.contacts,
    notification: 0,
    menuItems: [],
  },
  {
    id: 'groups',
    icon: 'group',
    to: '/groups',
    btnLabel: 'Create New Group',
    label: messages.group,
    notification: 0,
    menuItems: [
      // {
      //   id: 'myGroup',
      //   icon: 'shared',
      //   label: 'My Groups',
      //   to: '/groups/my-groups',
      //   count: 0,
      // },
      // {
      //   id: 'groupMemberships',
      //   icon: 'fileShareSmall',
      //   label: 'Group Memberships',
      //   to: '/groups/membership',
      //   count: 0,
      // },
      // {
      //   id: 'starred',
      //   icon: 'starred',
      //   label: 'Starred',
      //   to: '/groups/starred',
      //   count: 0,
      // },
    ],
  },
  // {
  //   id: 'call',
  //   icon: 'call',
  //   to: '/calls',
  //   label: messages.calls,
  //   notification: 0,
  //   menuItems: [],
  // },
  // {
  //   id: 'video',
  //   icon: 'video',
  //   to: '/meeting',
  //   label: messages.conferencing,
  //   notification: 0,
  //   btnLabel: 'Schedule New Meeting',
  //   menuItems: [
  //     // {
  //     //   id: 'conferencingAmera',
  //     //   icon: 'shared',
  //     //   label: 'Amera Conference',
  //     //   to: 'https://stream.amerashare.com/#/amera',
  //     //   external: true,
  //     //   count: 0,
  //     // },
  //     // {
  //     //   id: 'conferencingMCC',
  //     //   icon: 'shared',
  //     //   label: 'MCC Conference',
  //     //   to: 'https://stream.amerashare.com/#/mcc',
  //     //   external: true,
  //     //   count: 0,
  //     // },
  //     // {
  //     //   id: 'conferencingAmeraMCC',
  //     //   icon: 'shared',
  //     //   label: 'AmeraMCC Conference',
  //     //   to: 'https://stream.amerashare.com/#/ameramcc',
  //     //   external: true,
  //     //   count: 0,
  //     // },
  //     {
  //       id: 'meetingUpcoming',
  //       icon: 'add',
  //       label: 'Upcoming Meetings',
  //       to: '/video/meeting-upcoming',
  //       count: 0,
  //     },
  //     {
  //       id: 'meetingGroups',
  //       icon: 'shared',
  //       label: 'Group Meetings',
  //       to: '/video/meetings-group',
  //       count: 0,
  //     },
  //     {
  //       id: 'meetingPersonal',
  //       icon: 'addContact',
  //       label: 'Personal Meeting Room',
  //       to: '/video/meeting-personal',
  //       count: 0,
  //     },
  //     // {
  //     //   id: 'meetingTemplate',
  //     //   icon: 'history',
  //     //   label: 'Meeting Templates',
  //     //   to: '/video/meeting-templates',
  //     //   count: 0,
  //     // },
  //     {
  //       id: 'meetingHistory',
  //       icon: 'fileShareSmall',
  //       label: 'Previous Meetings',
  //       to: '/video/meeting-history',
  //       count: 0,
  //     },
  //   ],
  // },
  {
    id: 'mediaStreaming',
    icon: 'video',
    to: '/media-streaming',
    label: messages.streaming,
    menuItems: [
      // {
      //   id: 'scheduleBroadcastAmera',
      //   icon: 'shared',
      //   label: 'Schedule Broadcast',
      //   to: '/media-streaming/schedule-broadcast',
      //   count: 0,
      // },
      // {
      //   id: 'importAttendeesAmera',
      //   icon: 'shared',
      //   label: 'Import Attendees',
      //   to: '/media-streaming/import-attendees',
      //   count: 0,
      // },
      // {
      //   id: 'broadcastCalendarAmera',
      //   icon: 'shared',
      //   label: 'Broadcast Calendar',
      //   to: '/media-streaming/broadcast-calendar',
      //   count: 0,
      // },
      // {
      //   id: 'streamingAmera',
      //   icon: 'shared',
      //   label: 'Amera Room',
      //   to: 'https://stream.amerashare.com/#/amera',
      //   external: true,
      //   count: 0,
      // },
      // {
      //   id: 'streamingMCC',
      //   icon: 'shared',
      //   label: 'MCC Room',
      //   to: 'https://stream.amerashare.com/#/mcc',
      //   external: true,
      //   count: 0,
      // },
      // {
      //   id: 'streamingAmeraMCC',
      //   icon: 'shared',
      //   label: 'AmeraMCC Room',
      //   to: 'https://stream.amerashare.com/#/ameramcc',
      //   external: true,
      //   count: 0,
      // },
    ],
    notification: 0,
  },
  // {
  //   id: 'telephony',
  //   icon: 'telephony',
  //   to: '/telephony',
  //   label: messages.telephony,
  //   menuItems: [],
  //   notification: 0,
  // },
  {
    id: 'chat',
    icon: 'chat',
    to: '/contacts/chat',
    label: messages.chat,
    menuItems: [],
    notification: 0,
  },
  {
    id: 'mail',
    icon: 'mail',
    to: '/mail',
    label: messages.mail,
    notification: 0,
    // btnLabel: 'New Message',
    // hasCount: true,
    menuItems: [
      {
        id: 'mailInbox',
        icon: 'mailInbox',
        label: 'AMERAMAIL &#8482; Inbox',
        to: '/mail/inbox',
        // count: 7,
        color: '#3246D3',
      },

      {
        id: 'mailStarred',
        icon: 'mailStarred',
        label: 'Starred',
        to: '/mail/starred',
        // count: 0,
        color: '#3246D3',
      },
      {
        id: 'mailDrafts',
        icon: 'mailDrafts',
        label: 'Drafts',
        to: '/mail/drafts',
        // count: 2,
        color: '#F59B4D',
      },
      {
        id: 'mailSent',
        icon: 'mailSent',
        label: 'Sent',
        to: '/mail/sent',
        // count: 2,
        color: '#F59B4D',
      },
      {
        id: 'mailTrash',
        icon: 'mailTrash',
        label: 'Trash',
        to: '/mail/trash',
        // count: 0,
        color: '#F59B4D',
      },
      {
        id: 'mailArchive',
        icon: 'mailArchive',
        label: 'Archive',
        to: '/mail/archive',
        // count: 0,
        color: '#F59B4D',
      },
      {
        id: 'mailSettings',
        icon: 'mailSettings',
        label: 'Settings',
        to: '/mail/settings',
        // count: 0,
        color: '#F59B4D',
      },
    ],
    // filterItems: [
    //   { color: '#3246D3', label: 'Work', id: 'work' },
    //   { color: '#F59B4D', label: 'Family', id: 'family' },
    //   { color: '#00D0FF', label: 'Friends', id: 'friends' },
    //   { color: '#EE3158', label: 'Clients', id: 'clients' },
    // ],
  },
  {
    id: 'fileShare',
    icon: 'fileShare',
    to: '/fileshare',
    notification: 0,
    label: messages.fileSharing,
    btnLabel: 'Encrypt files (AMERA App or Cloud)',
    secondBtnLabel: 'Decrypt files (AMERA App)',
    menuItems: [
      // {
      //   id: 'filePriority',
      //   icon: 'add',
      //   label: 'Priority',
      //   to: '/fileshare/priority',
      //   count: 0,
      // },
      // {
      //   id: 'fileMyFiles',
      //   icon: 'fileShareSmall',
      //   label: 'My Files',
      //   to: '/fileshare/my-files',
      //   count: 0,
      // },
      // {
      //   id: 'sharedGroupFiles',
      //   icon: 'shared',
      //   label: 'Group Files',
      //   to: '/fileshare/group-files',
      //   count: 0,
      // },
      // {
      //   id: 'sharedFiles',
      //   icon: 'shared',
      //   label: 'Shared Files',
      //   to: '/fileshare/shared-files',
      //   count: 0,
      // },
      // {
      //   id: 'fileall',
      //   icon: 'fileShareSmall',
      //   label: 'All Files',
      //   to: '/fileshare/all',
      //   count: 0,
      // },
      // {
      //   id: 'filehistory',
      //   icon: 'history',
      //   label: 'History',
      //   to: '/fileshare/history',
      //   count: 0,
      // },
    ],
  },
  {
    id: 'calendar',
    icon: 'calendar',
    to: '/calendar',
    label: messages.calendar,
    notification: 0,
    btnLabel: 'Create New Event',
    menuItems: [
      // {
      //   id: 'callUpcoming',
      //   icon: 'communicationSmall',
      //   label: 'Upcoming Events',
      //   to: '/calendar/upcoming-events',
      //   count: 0,
      // },
      // {
      //   id: 'conferenceGroup',
      //   icon: 'groupSubMenu',
      //   label: 'Group Conferences',
      //   to: '/calendar/group-conference',
      //   count: 0,
      // },
      // {
      //   id: 'collaborativeCalendar',
      //   icon: 'calendarSubmenu',
      //   label: 'Collaborative Calendar',
      //   to: '/calendar/callaborative-calendar',
      //   count: 0,
      // },
      // {
      //   id: 'eventPrevious',
      //   icon: 'history',
      //   label: 'Previous Events',
      //   to: '/calendar/prev-events',
      //   count: 0,
      // },
    ],
  },
  // {
  //   id: 'projects',
  //   icon: 'projects',
  //   to: '/projects',
  //   btnLabel: '',
  //   label: messages.projects,
  //   notification: 0,
  //   menuItems: [],
  // },
  // {
  //   id: 'transaction',
  //   icon: 'transaction',
  //   to: '/billing',
  //   label: messages.transaction,
  //   notification: 0,
  //   menuItems: [],
  // },

  // {
  //   id: 'statistic',
  //   icon: 'statistic',
  //   to: '/statistic',
  //   label: messages.statistic,
  //   notification: 0,
  //   menuItems: [],
  // },
  {
    id: 'logout',
    icon: 'logout',
    to: '/logout',
    label: messages.logout,
    notification: 0,
    menuItems: [],
  },
  // {
  //   id: 'communication',
  //   icon: 'communication',
  //   to: '/communication',
  //   label: messages.communication,
  //   notification: 0,
  //   menuItems: [],
  // },
];

export default navItems;
