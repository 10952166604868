import {
  APP_START,
  APP_ERROR,
  APP_INITIALIZATION_END,
  CHANGE_LOCALE,
  LOAD_TRANSLATIONS,
  APP_MESSAGE_UPDATE,
  APP_RESET,
} from '../actionTypes/app.js';

import * as api from '../../config/api';

const apiUrl = api.apiUrl;

export const changeLocale = (locale) => {
  return {
    type: CHANGE_LOCALE,
    payload: {
      locale,
    },
  };
};

export const isAppLoadingOrLogin =
  (
    appState,
    languageLoaded,
    loadingSession,
    loginAttempt,
    loginCompleted,
    redirectingToDashboard
  ) =>
  () => {
    console.debug(
      `isAppLoadingOrLogin: React/store/etc initializing: ${
        appState === 'INITIALIZING'
      }`
    );
    console.debug(
      `isAppLoadingOrLogin: React/store/etc running: ${appState === 'RUNNING'}`
    );
    console.debug(`isAppLoadingOrLogin: React/store/etc state: ${appState}`);
    console.debug(
      `isAppLoadingOrLogin: We now have languages loaded: ${languageLoaded}`
    );
    console.debug(
      `isAppLoadingOrLogin: We are done loading the session wether true or false: ${loadingSession}`
    );
    console.debug(
      `isAppLoadingOrLogin: We are attempting login: ${loginAttempt}`
    );
    console.debug(
      `isAppLoadingOrLogin: We have completed login: ${loginCompleted}`
    );
    console.debug(
      `isAppLoadingOrLogin: We are redirecting to dashboard: ${redirectingToDashboard}`
    );
    if (
      (loginAttempt === true && loginCompleted === false) ||
      redirectingToDashboard === true ||
      appState === 'INITIALIZING' ||
      languageLoaded === false ||
      loadingSession === true
    ) {
      console.debug('isAppLoadingOrLogin: WE ARE SHOWING LOADER');
      return true;
    }
    console.debug('isAppLoadingOrLogin: WE ARE HIDING LOADER');
    return false;
  };

export const loadAppLanguage = () => async (dispatch) => {
  const url = `${apiUrl}/languages`;
  dispatch({ type: APP_START });
  try {
    const res = await api.GET(url);
    dispatch({ type: LOAD_TRANSLATIONS, payload: res });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: APP_INITIALIZATION_END,
    });
  }
  return true;
};

export const updateMessage = (payload) => (dispatch) => {
  const url = `${apiUrl}/languages`;
  api
    .POST(url, JSON.stringify(payload), { 'Content-Type': 'application/json' })
    .then((res) => {
      dispatch({ type: APP_MESSAGE_UPDATE, payload });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const appError = (payload) => (dispatch) => {
  dispatch({ type: APP_ERROR, payload });
};

export const resetApp = () => ({
  type: APP_RESET,
});
