import React, { useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Strophe } from 'strophe.js';
import { Alert } from '@material-ui/lab';
import Grow from '@material-ui/core/Grow';

import Layout from '../../Sections/Layout';
import ChatsPanel from './ChatsPanel';
import ChatMessages from '../shared/ChatMessages';
import ChatForm from '../shared/ChatForm';

import { getGroupIDFromChatRoomID } from '../../../utils/general';
import * as api from '../../../config/api';

import { enableE2EEncryption } from '../../../redux/actions/security';

import {
  joinChatRoom,
  sendGroupMessage,
  // leaveGroup,
} from '../../../redux/actions/chat';

// function dec2hexString(dec) {
//   return '0x' + (dec + 0x10000).toString(16).substr(-4).toUpperCase();
// }

const getDetailFromGroupList = (state, group_id) => {
  const groups = state?.group?.groupList;
  const detail = groups.find((group) => group.group_id === group_id);
  return detail;
};
const getDetailFromGroupMembershipList = (state, group_id) => {
  const groups = state?.group?.groupMembershipList;
  const detail = groups.find((group) => group.group_id === group_id);
  return detail;
};

export const getGroupDetail = createSelector(
  [getDetailFromGroupList, getDetailFromGroupMembershipList],
  (detailFromGroupList, detailFromMembershipList) => {
    if (detailFromGroupList) return detailFromGroupList;
    return detailFromMembershipList;
  }
);

function GroupChat(props) {
  const {
    match: {
      params: { room },
    },
  } = props;
  const dispatch = useDispatch();
  const { connectionStatus } = useSelector((s) => ({
    connectionStatus: s.chat.connectionStatus,
  }));
  const [errorMsg, setErrorMsg] = useState(null);

  const group_id = useMemo(() => getGroupIDFromChatRoomID(room), [room]);

  const groupDetail = useSelector((state) => getGroupDetail(state, group_id));

  const scrollToBottom = () => {
    if (messagesEnfRef && messagesEnfRef.current) {
      messagesEnfRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  React.useEffect(() => {
    if (room && connectionStatus === Strophe.Status.CONNECTED) {
      api
        .GET(`${api.getAPIUrl()}/valid-session?room=${room}`)
        .then(() => dispatch(joinChatRoom(room)))
        .catch(async () => {
          const msg = 'Group validation failed! Redirecting you to Login Page.';
          setErrorMsg(msg);
          setTimeout(() => api.redirectToLogin(), 4000);
        });
    }
    // return () => {
    //   if (room) {
    //     dispatch(leaveGroup());
    //   }
    // };
  }, [dispatch, room, connectionStatus]);

  React.useEffect(() => {
    return () => {
      dispatch(enableE2EEncryption(false));
    };
  }, [dispatch]);

  const messagesEnfRef = useRef();

  const handleSendMessage = (messageText) => {
    dispatch(sendGroupMessage(room, messageText));
    setTimeout(() => scrollToBottom(), 100);
  };

  const disabledInput = React.useMemo(
    () => connectionStatus !== Strophe.Status.CONNECTED,
    [connectionStatus]
  );

  return (
    <Layout {...props}>
      {groupDetail && (
        <div className="chat-section">
          <div className="messages-container">
            <ChatMessages
              isGroupChat={true}
              room={room}
              textSecondary="Please wait while Group Chat is loading..."
              messagesEnfRef={messagesEnfRef}
            />
            <ChatForm
              disabled={disabledInput}
              handleSendMessage={handleSendMessage}
              disableFileInput={true} //TODO: add file share feature for group chat
              type="groupchat"
              group_id={group_id}
              group_leader_id={groupDetail.group_leader_id}
              room={room}
            />
          </div>
          <div className="chats-panel">
            <ChatsPanel />
          </div>
        </div>
      )}
      {!!errorMsg && (
        <Grow in={true}>
          <Alert variant="filled" severity="error" className="chat-error">
            {errorMsg}
          </Alert>
        </Grow>
      )}
    </Layout>
  );
}

export default GroupChat;
