import React from 'react';
import { makeStyles } from '@material-ui/core';
import Header, { messages as headerMessages } from '../Header/GuestHeader';
import Footer from '../Footer/GuestFooter';
import AmeraStaticBanner from '../../../components/Banner/AmeraStaticBanner';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: 'calc(100vh - 249px)',
    display: 'flex',
  },
}));

export const messages = { ...headerMessages };

const Layout = (props) => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <AmeraStaticBanner />
      <div className={classes.main}>{props.children}</div>
      <Footer />
    </>
  );
};

export default Layout;
