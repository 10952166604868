import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Typography, Button, Grid } from '@material-ui/core';

import MemberAutocomplete from '../MemberAutocomplete';
import Modal from '../Modal/modal';
import { getConferenceID } from '../../utils/general';

import { addGroupMember, setReduxGroupAlert } from '../../redux/actions/group';

const AddMemberModal = (props) => {
  const { title, open, onClose, groupId, groupData, loading, groupAlert } =
    props;
  const [addMember, setAddMember] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setDisableSubmit(!!!addMember.contact_member_id);
  }, [addMember]);

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const addedMembers = useMemo(() => {
    if (groupData.members && groupData.members.length) {
      return groupData.members.map((member) => {
        return member.member_id;
      });
    } else {
      return [];
    }
  }, [groupData]);

  const isAlreadyAddedMembers = useMemo(() => {
    return (option) => addedMembers.includes(option.contact_member_id);
  }, [addedMembers]);

  useEffect(() => {
    if (groupAlert.show) {
      closeModal();
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: groupAlert.variant ? groupAlert.variant : 'success',
          message: groupAlert.message ? groupAlert.message : '',
        };
        dispatch(setReduxGroupAlert(alertOption));
      }, 5000);
    }
  }, [dispatch, groupAlert, closeModal]);

  const handleSubmit = () => {
    let formData = new FormData();
    formData.set('group_id', groupId);
    formData.set('name', groupData.group_name);
    formData.set('type', 'add-group-member');
    formData.set('contact_member_id', addMember.contact_member_id);

    console.log('groupData', groupData);
    console.log('room', getConferenceID(groupData));
    dispatch(addGroupMember(formData, getConferenceID(groupData)));
    // closeModal();
  };

  const handleMembersSelect = (e, v) => {
    if (v) {
      setAddMember(v);
    } else {
      setAddMember({});
    }
  };

  const ModalContent = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Please select a member to add to your group
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MemberAutocomplete
          handleChange={handleMembersSelect}
          disablerFunction={isAlreadyAddedMembers}
          label="Add Member"
          isAddMember={true}
        />
      </Grid>
    </Grid>
  );

  const ModalAction = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => closeModal()}
          disabled={loading}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disabled={disableSubmit}
          >
            Adding...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            Add Member
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      disabled={loading}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="add-member-modal"
    ></Modal>
  );
};

AddMemberModal.defaultProps = {
  dispatch: null,
  open: false,
  onClose: null,
  group_id: null,
  loading: false,
  groupAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  groupData: state.group.groupData,
  loading: state.group.loading,
  groupAlert: state.group.groupAlert,
});

export default connect(mapStateToProps)(AddMemberModal);
