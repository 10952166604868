import { apiUrl } from '../config/api';

/**
 *
 * @param {Number} picture_id
 * @param {Number|String} pin
 * @returns {Promise<Object>}
 */
export const getKeyFromStorage = async (picture_id, pin) => {
  const data = new FormData();
  data.append('storage_id', String(picture_id));
  data.append('pin', String(pin));
  const url = `${apiUrl}/keygen/storage`;

  return fetch(url, {
    method: 'POST',
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error('Error fetching key');
      console.error(err);
      return null;
    });
};

/**
 *
 * @param {Blob} image
 * @param {Number|String} pin
 * @returns {Promise<Object>}
 */
export const getKeyFromFile = (image, pin) => {
  const data = new FormData();
  data.append('image', image);
  data.append('pin', String(pin));
  const url = `${apiUrl}/keygen/file`;

  return fetch(url, {
    method: 'POST',
    body: data,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error('Error fetching key');
      console.error(err);
      return null;
    });
};
