import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import * as api from '../../config/api';
import { setReduxMemberAlert } from '../../redux/actions/member';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import axios from 'axios';
import { getConferenceID } from '../../utils/general';
import {
  createDefaultChatRoom,
  destroyGroupChatRoom,
} from '../../redux/actions/chat';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    height: 'calc(100vh - 332px)',
    padding: '8px',
  },
}));

const AdminConfiguration = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { memberAlert } = useSelector((state) => ({
    memberAlert: state.member.memberAlert,
  }));

  const [isCreatingRoom, setIsCreatingRoom] = useState(false);
  const [isDestroyingRoom, setIsDestroyingRoom] = useState(false);

  const handleSnackbarClose = useCallback(() => {
    if (memberAlert.show) {
      const alertOption = {
        show: false,
        variant: memberAlert.variant,
        message: '',
      };
      dispatch(setReduxMemberAlert(alertOption));
    }
  }, [dispatch, memberAlert]);

  useEffect(() => {
    if (memberAlert.show) {
      setTimeout(() => {
        handleSnackbarClose();
      }, 4000);
    }
  }, [memberAlert, handleSnackbarClose]);

  const createDefaultRooms = () => {
    if (isCreatingRoom) return;
    setIsCreatingRoom(true);
    axios
      .get(`${api.getAPIUrl()}/admin/groups`)
      .then((res) => {
        console.log('res ====================>', res);
        if (res.data.groups && res.data.groups.length > 0) {
          res.data.groups.map((group) => {
            const conferenceID = getConferenceID(group);
            console.log(
              'dispatch creating default rooms ==========>',
              conferenceID
            );
            console.log('group info =================>', group);
            dispatch(createDefaultChatRoom(conferenceID, group.members));
            return null;
          });
        }
        setIsCreatingRoom(false);
      })
      .catch((err) => {
        console.log('admin groups err===================>', err);
      });
  };

  const destroyGroupRooms = () => {
    if (isDestroyingRoom) return;
    setIsDestroyingRoom(true);
    axios
      .get(`${api.getAPIUrl()}/admin/groups`)
      .then((res) => {
        console.log('res ====================>', res);
        if (res.data.groups && res.data.groups.length > 0) {
          res.data.groups.map((group) => {
            const conferenceID = getConferenceID(group);
            console.log('dispatch destroying rooms ==========>', conferenceID);
            console.log('group info =================>', group);
            dispatch(destroyGroupChatRoom(conferenceID));
            return null;
          });
        }
        setIsDestroyingRoom(false);
      })
      .catch((err) => {
        console.log('admin groups err===================>', err);
      });
  };

  return (
    <div className={classes.container}>
      <Button variant="contained" color="primary" onClick={destroyGroupRooms}>
        Destroy group rooms
        <div className="ml-2 d-flex">
          {isDestroyingRoom ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <PlayArrowIcon />
          )}
        </div>
      </Button>

      <Button variant="contained" color="primary" onClick={createDefaultRooms}>
        Create default group rooms
        <div className="ml-2 d-flex">
          {isCreatingRoom ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <PlayArrowIcon />
          )}
        </div>
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={memberAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={memberAlert.variant}>
          {memberAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminConfiguration;
