import React from 'react';

import USAddressLine from './USAddressLine';
import JPAddressLine from './JPAddressLine';

const AddressLine = ({ data, simple = false }) => {
  return data.country_code_id === 392 || data.country_alpha_2 === 'JP' ? (
    <JPAddressLine data={data} simple={simple} />
  ) : (
    <USAddressLine data={data} simple={simple} />
  );
};

export default AddressLine;
