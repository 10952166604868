import React from 'react';
import { makeStyles } from '@material-ui/core';
import { textMedium } from '../../../jss/colors';
import AMERAIoTLogoShield from '../../../assets/img/amera-iot-shield.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#dce1ec',
    height: '54px',
    color: textMedium,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    // textAlign: 'center'
  },
  poweredBy: {
    float: 'right',
    display: 'flex',
    justifyContent: 'right',
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span>&nbsp;</span>
      <span>© Copyright 2021 AMERA IoT&#8482; Inc. - All Rights Reserved</span>
      <div className={classes.poweredBy}>
        <i>Powered by</i>
        <img
          src={AMERAIoTLogoShield}
          alt="AMERA IoT™ Inc."
          className={'iot-shield-icon'}
        />
        AMERA IoT&#8482;
      </div>
    </div>
  );
};

export default Footer;
